import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import config from './config'

export default class PostService {
    axiosInstance = false;
    axiosRefurbedInstance = false;

    constructor(email, postcode) {
        this.axiosInstance = rateLimit(
            axios.create({
                baseURL: config.hostname,
                'Content-Type': 'application/json',
                auth: {
                    username: email ? email : false,
                    password: postcode ? postcode : false
                }
            }), { maxRequests: 6, perMilliseconds: 1000 });

        this.axiosInstanceNew = rateLimit(
            axios.create({
                baseURL: 'https://laravel.nexchance.de/api',
                'Content-Type': 'application/json'
            }), { maxRequests: 6, perMilliseconds: 1000 });

        this.axiosRefurbedInstance = rateLimit(
            axios.create({
                baseURL: 'https://api.refurbed.com',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Plain 372a70f5-31ca-5c3f-8b49-f12c64a1e884'
                }
            }), { maxRequests: 6, perMilliseconds: 1000 });
    }

    Login(email, password) {
        return this.axiosInstance.post(`/login`, {
            'email': email,
            'password': password
        });
    }

    fetchRefurbedOffer(sku) {
        return this.axiosRefurbedInstance.post(`/refb.merchant.v1.OfferService/GetOffer`, {
            'identifier': {
                'sku': String(sku)
            }
        });
    }

    createRefurbedOffer(offer) {
        return this.axiosRefurbedInstance.post(`/refb.merchant.v1.OfferService/CreateOffer`, {
            'sku': String(offer.sku),
            'stock': 0,
            'shipping_profile_id': offer.shipping_profile_id,
            'reference_price': Number(offer.reference_price ? offer.reference_price : 9999).toFixed(2),
            'instance_id': offer.instance_id,
            //'gtin': offer.ean,
            'grading': offer.grading,
            'warranty': offer.warranty,
            //'secondary_shipping_profile_id': 'occaecat reprehenderit adipisicing sed',
            'taxation': offer.taxation,
            'reference_currency_code': offer.reference_currency_code,
            'reference_min_price': Number(offer.reference_min_price ? offer.reference_min_price : 9999).toFixed(2),
        });
    }

    fetchRefurbedInstance(instance_id) {
        return this.axiosRefurbedInstance.post(`/refb.merchant.v1.InstanceService/GetInstance`, {
            'id': instance_id
        });
    }

    fetchRefurbedBuyBox(country) {
        return this.axiosRefurbedInstance.post(`/refb.merchant.v1.MarketOfferService/ListMarketOffers`, {
            'sort': {
                'order': 'ASC',
                'by': 'ID'
            },
            'filter': {
                'stock': {
                    'gt': 0
                },
                'market_price_market_code': {
                    'any_of': [
                        country
                    ]
                },
                'market_price_visibility': {
                    'any_of': [
                        'FULL'
                    ]
                },
                'market_price_instance_buybox_state': {
                    'any_of': [
                        'ELIGIBLE'
                    ]
                }
            }
        });
    }

    UpdateMarketOffer(item) {
        return this.axiosRefurbedInstance.post(`/refb.merchant.v1.MarketOfferService/UpdateMarketOffer`, {
            'identifier': {
                'market_code': item.id.market_code,
                'offer_id': {
                    'sku': item.sku
                }
            },
            'market_price': {
                'currency': item.market_price.currency_code,
                'price': Number(item.market_price.instance_buybox_info
                    .suggested_offer_price).toFixed(2),
            }
        });
    }

    UpdateMarketOfferByData(market_code, sku, currency, price) {
        return this.axiosRefurbedInstance.post(`/refb.merchant.v1.MarketOfferService/UpdateMarketOffer`, {
            'identifier': {
                'market_code': market_code,
                'offer_id': {
                    'sku': sku
                }
            },
            'market_price': {
                'currency': currency,
                'price': Number(price).toFixed(2),
            }
        });
    }

    fetchArticles(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/articles?${uri}`);
    }

    fetchArticleBySkuNew(sku) {
        return this.axiosInstanceNew.get(`/VARIO/ART/VIEW/ARTIKELNR/${sku}`);
    }

    fetchArticle(id) {
        return this.axiosInstance.get(`/vario/articles/${id}`);
    }

    fetchArticlePrices(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/articles/prices?${uri}`);
    }

    fetchArticlePricesRefurbedReset() {
        return this.axiosInstance.get(`/vario/articles/prices/refurbed-reset`);
    }

    patchArticlePrice(sku, params) {
        return this.axiosInstance.patch(`/vario/articles/prices/${sku}`, params);
    }

    fetchImages(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/images?${uri}`);
    }

    viewImage(id) {
        return this.axiosInstance.get(`/vario/images/${id}/view`);
    }

    fetchArticleAttributes(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/articles/attributes?${uri}`);
    }

    fetchArticleAttachments(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/articles/attachments?${uri}`);
    }

    fetchOrdersReturn(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/order/return?${uri}`);
    }

    fetchOrderReturn(id) {
        return this.axiosInstance.get(`/system/order/return/${id}`);
    }

    postOrderReturnLabel(id) {
        return this.axiosInstance.post(`/system/order/return/label/${id}`);
    }

    postOrderWarrantyLabel(id) {
        return this.axiosInstance.post(`/system/order/warranty/label/${id}`);
    }

    fetchOrdersReturnArticles(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/order/return/articles?${uri}`);
    }

    createOrderReturn(order_number, reason, description, ext_type, ext_reference, articles) {
        return this.axiosInstance.post(`/system/order/return`, {
            'order_number': order_number,
            'reason': reason,
            'description': description,
            'ext_type': ext_type,
            'ext_reference': ext_reference,
            'articles': articles
        });
    }

    fetchOrderReturnAttachments(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/order/return/attachments?${uri}`);
    }


    fetchOrdersWarranty(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/order/warranty?${uri}`);
    }

    fetchOrderWarranty(id) {
        return this.axiosInstance.get(`/system/order/warranty/${id}`);
    }

    fetchOrdersWarrantyArticles(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/order/warranty/articles?${uri}`);
    }

    createOrderWarranty(order_number, reason, description, ext_type, ext_reference, articles) {
        return this.axiosInstance.post(`/system/order/warranty`, {
            'order_number': order_number,
            'reason': reason,
            'description': description,
            'ext_type': ext_type,
            'ext_reference': ext_reference,
            'articles': articles
        });
    }

    fetchOrderWarrantyAttachments(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/order/warranty/attachments?${uri}`);
    }


    fetchOrders(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders?${uri}`);
    }

    fetchPresentReceiptOrders(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders/search/receipt/present?${uri}`);
    }

    fetchMissingReceiptOrders(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders/search/receipt/missing?${uri}`);
    }

    fetchReturnReceiptOrders(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders/search/receipt/return?${uri}`);
    }

    fetchWarrantyReceiptOrders(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders/search/receipt/warranty?${uri}`);
    }

    fetchOrder(order_number) {
        return this.axiosInstance.get(`/vario/orders/${order_number}`);
    }

    fetchOrderArticles(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders/articles?${uri}`);
    }

    fetchOrderAddresses(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/orders/addresses?${uri}`);
    }

    fetchOrderLabels(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/vario/shipping/labels?${uri}`);
    }

    fetchOrderInvoice(order_id) {
        return this.axiosInstance.get(`/pdf/invoice/${order_id}`);
    }

    createArticle(article_id) {
        return this.axiosInstance.post(`system/cron/vario:article:create/${article_id}`);
    }

    fetchAlertReplication(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/replication?${uri}`);
    }

    fetchAlertAddressReplication(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/replication/address?${uri}`);
    }

    fetchAlertArticleReplication(params) {
        const uri = Object.keys(params).map(value => `${value}${encodeURIComponent(params[value])}`).join('&');

        return this.axiosInstance.get(`/system/replication/article?${uri}`);
    }

    clearVarioCache() {
        return this.axiosInstance.get(`/system/cron/vario:cache:clear`);
    }
}