<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="input"
    solo-inverted
    flat
    hide-details
    hide-no-data
    hide-selected
    return-object
    item-text="description"
    label="Suche"
    chips
    prepend-inner-icon="mdi-magnify"
    color="#FAFAFA"
    background-color="blue-grey lighten-2"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
      >
        <v-avatar left>
          <v-icon :color="data.item.color">{{ data.item.icon }}</v-icon>
        </v-avatar>
        {{ data.item.description }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <v-list-item-action>
        <v-icon :color="data.item.color">{{ data.item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title
          :to="data.item.url"
          v-text="data.item.description"
        ></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        {{ data.item.descriptionRight }}
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import PostService from "./../PostService";

export default {
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    return {
      items: [],
      isLoading: false,
      model: null,
      input: null,
      timerId: null,
    };
  },
  methods: {
    fetchOrders() {
      return this.postService
        .fetchOrders({
          "filter[BELEGNR]=": this.input,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "order=": "BELEGNR",
          "direction=": "ASC",
          "limit=": "5",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            res.data.data.map((item) => {
              item.icon = "mdi-package-variant-closed";
              item.color = "#009688";
              item.description = item.BELEGNR;
              item.descriptionRight = item.BETREFF;
              item.type = "Bestellung";
              item.url = `/orders/${item.ID}`;

              this.items.push(item);

              return item;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrdersByBetreff() {
      return this.postService
        .fetchOrders({
          "filter[BETREFF]=": this.input,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "order=": "BELEGNR",
          "direction=": "ASC",
          "limit=": "5",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            res.data.data.map((item) => {
              item.icon = "mdi-package-variant-closed";
              item.color = "#009688";
              item.description = item.BETREFF;
              item.descriptionRight = item.BELEGNR;
              item.type = "Bestellung";
              item.url = `/orders/${item.ID}`;

              this.items.push(item);

              return item;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrdersReturn() {
      return this.postService
        .fetchOrdersReturn({
          "filter[id]=": this.input,
          "order=": "id",
          "direction=": "ASC",
          "limit=": "5",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            res.data.data.map((item) => {
              item.icon = "mdi-truck-delivery-outline";
              item.color = "#3F51B5";
              item.description = item.id;
              item.type = "Retoure";
              item.url = `/return/${item.id}`;

              this.items.push(item);

              return item;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrdersWarranty() {
      return this.postService
        .fetchOrdersWarranty({
          "filter[id]=": this.input,
          "order=": "id",
          "direction=": "ASC",
          "limit=": "5",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            res.data.data.map((item) => {
              item.icon = "mdi-hammer-wrench";
              item.color = "#FF5722";
              item.description = item.id;
              item.type = "Serviceantrag";
              item.url = `/warranty/${item.id}`;

              this.items.push(item);

              return item;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  watch: {
    input() {
      // Check if not empty
      if (!this.input || !this.input.length) return;

      // Cancel pending call
      clearTimeout(this.timerId);

      this.isLoading = true;

      // delay new call 500ms
      this.timerId = setTimeout(() => {
        this.items = new Array();

        this.isLoading = true;

        let fetchOrders = this.fetchOrders();
        let fetchOrdersByBetreff = this.fetchOrdersByBetreff();
        let fetchOrdersReturn = this.fetchOrdersReturn();
        let fetchOrdersWarranty = this.fetchOrdersWarranty();

        Promise.all([
          fetchOrders,
          fetchOrdersByBetreff,
          fetchOrdersWarranty,
          fetchOrdersReturn,
        ]).then(() => {
          this.isLoading = false;
        });
      }, 500);
    },
    model: function (newVal) {
      if (newVal !== null && Object.keys(newVal).includes("url")) {
        this.$router.push(newVal.url);
      }
    },
    $route(to) {
      if (
        to.name !== "orders_detail" &&
        to.name !== "orders_warranty_details" &&
        to.name !== "orders_return_detail"
      ) {
        this.model = null;
      }
    },
  },
};
</script>