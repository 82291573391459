<template>
  <v-dialog @input="close" :value="dialog" max-width="800">
    <v-card outlined :loading="loading">
      <v-card-title class="headline">Technische Daten</v-card-title>
      <v-divider></v-divider>

      <v-card-text v-if="articleAttributes">
        <v-simple-table
          v-if="
            articleAttributes.FELD1 ||
            articleAttributes.FELD3 ||
            articleAttributes.FELD9 ||
            articleAttributes.FELD10 ||
            articleAttributes.FELD18 ||
            articleAttributes.FELD67 ||
            articleAttributes.FELD81
          "
        >
          <v-card-title>Prozessor</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD1">
              <td width="50%">Hersteller</td>
              <td width="50%">{{ articleAttributes.FELD1 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD67">
              <td width="50%">Typ</td>
              <td width="50%">{{ articleAttributes.FELD67 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD18">
              <td width="50%">Modell</td>
              <td width="50%">{{ articleAttributes.FELD18 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD3">
              <td width="50%">Kerne</td>
              <td width="50%">{{ articleAttributes.FELD3 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD81 && articleAttributes.FELD10">
              <td width="50%">Taktfrequenz</td>
              <td width="50%">
                {{ articleAttributes.FELD81 | formatNumberPrettify
                }}{{ articleAttributes.FELD10 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD9 && articleAttributes.FELD10">
              <td width="50%">Turbo-Taktfrequenz</td>
              <td width="50%">
                {{ articleAttributes.FELD9 | formatNumberPrettify
                }}{{ articleAttributes.FELD10 }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD7 ||
            articleAttributes.FELD13 ||
            articleAttributes.FELD14 ||
            articleAttributes.FELD19 ||
            articleAttributes.FELD84 ||
            articleAttributes.FELD91
          "
        >
          <v-card-title>Arbeitsspeicher</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD7">
              <td width="50%">Generation</td>
              <td width="50%">
                {{ articleAttributes.FELD7 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD19">
              <td width="50%">Typ</td>
              <td width="50%">
                {{ articleAttributes.FELD19 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD85">
              <td width="50%">Art</td>
              <td width="50%">
                {{ articleAttributes.FELD85 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD82">
              <td width="50%">Anzahl Slots</td>
              <td width="50%">
                {{ articleAttributes.FELD82 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD13 && articleAttributes.FELD14">
              <td width="50%">Kapazität</td>
              <td width="50%">
                {{ articleAttributes.FELD13 }}{{ articleAttributes.FELD14 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD83 && articleAttributes.FELD14">
              <td width="50%">Kapazität (OnBoard)</td>
              <td width="50%">
                {{ articleAttributes.FELD83 }}{{ articleAttributes.FELD14 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD84 && articleAttributes.FELD91">
              <td width="50%">Kapazität (Max)</td>
              <td width="50%">
                {{ articleAttributes.FELD84 }}{{ articleAttributes.FELD91 }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD4 ||
            articleAttributes.FELD6 ||
            articleAttributes.FELD8 ||
            articleAttributes.FELD20 ||
            articleAttributes.FELD22
          "
        >
          <v-card-title>Festplatte</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD6">
              <td width="50%">Interface</td>
              <td width="50%">{{ articleAttributes.FELD6 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD22">
              <td width="50%">Format</td>
              <td width="50%">
                {{ articleAttributes.FELD22 | formatNumberPrettify }} Zoll
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD4 && articleAttributes.FELD8">
              <td width="50%">Kapazität</td>
              <td width="50%">
                {{ articleAttributes.FELD4 }}{{ articleAttributes.FELD8 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD20">
              <td width="50%">Technologie</td>
              <td width="50%">{{ articleAttributes.FELD20 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD5 ||
            articleAttributes.FELD16 ||
            articleAttributes.FELD17 ||
            articleAttributes.FELD21 ||
            articleAttributes.FELD23
          "
        >
          <v-card-title>Festplatte 2</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD16">
              <td width="50%">Interface</td>
              <td width="50%">{{ articleAttributes.FELD16 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD23">
              <td width="50%">Format</td>
              <td width="50%">
                {{ articleAttributes.FELD23 | formatNumberPrettify }} Zoll
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD5 && articleAttributes.FELD17">
              <td width="50%">Kapazität</td>
              <td width="50%">
                {{ articleAttributes.FELD5 }}{{ articleAttributes.FELD17 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD21">
              <td width="50%">Technologie</td>
              <td width="50%">{{ articleAttributes.FELD21 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD29 ||
            articleAttributes.FELD30 ||
            articleAttributes.FELD108 ||
            articleAttributes.FELD109 ||
            articleAttributes.FELD110
          "
        >
          <v-card-title>Grafikkarte</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD29">
              <td width="50%">Hersteller</td>
              <td width="50%">{{ articleAttributes.FELD29 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD30">
              <td width="50%">Modell</td>
              <td width="50%">{{ articleAttributes.FELD30 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD108">
              <td width="50%">Interface</td>
              <td width="50%">{{ articleAttributes.FELD108 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD109 && articleAttributes.FELD110">
              <td width="50%">Kapazität</td>
              <td width="50%">
                {{ articleAttributes.FELD109 }}{{ articleAttributes.FELD110 }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD24 ||
            articleAttributes.FELD25 ||
            articleAttributes.FELD26 ||
            articleAttributes.FELD27 ||
            articleAttributes.FELD28
          "
        >
          <v-card-title>Display</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD24">
              <td width="50%">Technologie</td>
              <td width="50%">{{ articleAttributes.FELD24 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD25">
              <td width="50%">Größe</td>
              <td width="50%">{{ articleAttributes.FELD25 }} Zoll</td>
            </tr>
            <tr v-if="articleAttributes.FELD26">
              <td width="50%">Auflösung</td>
              <td width="50%">{{ articleAttributes.FELD26 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD25 && articleAttributes.FELD27">
              <td width="50%">Diagonale</td>
              <td width="50%">
                {{ articleAttributes.FELD25 }} {{ articleAttributes.FELD27 }}
              </td>
            </tr>
            <tr v-if="articleAttributes.FELD28">
              <td width="50%">Touchscreen</td>
              <td width="50%">{{ articleAttributes.FELD28 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="articleAttributes.FELD60">
          <v-card-title>Gehäuse</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD60">
              <td width="50%">Form</td>
              <td width="50%">{{ articleAttributes.FELD60 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD1 ^ 5">
              <td width="50%">Farbe</td>
              <td width="50%">{{ articleAttributes.FELD15 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="articleAttributes.FELD11 || articleAttributes.FELD12">
          <v-card-title>Betriebssystem</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD12">
              <td width="50%">Betriebssystem</td>
              <td width="50%">{{ articleAttributes.FELD12 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="articleAttributes.FELD31">
          <v-card-title>Optisches Laufwerk</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD31">
              <td width="50%">Optisches Laufwerk</td>
              <td width="50%">{{ articleAttributes.FELD31 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD32 ||
            articleAttributes.FELD33 ||
            articleAttributes.FELD34 ||
            articleAttributes.FELD35 ||
            articleAttributes.FELD36 ||
            articleAttributes.FELD37 ||
            articleAttributes.FELD70
          "
        >
          <v-card-title>Kommunikationsanschlüsse</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD32">
              <td width="50%">USB 2.0</td>
              <td width="50%">{{ articleAttributes.FELD32 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD33">
              <td width="50%">USB 3.0</td>
              <td width="50%">{{ articleAttributes.FELD33 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD34">
              <td width="50%">USB-C</td>
              <td width="50%">{{ articleAttributes.FELD34 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD35">
              <td width="50%">Micro-USB</td>
              <td width="50%">{{ articleAttributes.FELD35 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD36">
              <td width="50%">Mini-USB</td>
              <td width="50%">{{ articleAttributes.FELD36 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD70">
              <td width="50%">RJ45 LAN</td>
              <td width="50%">{{ articleAttributes.FELD70 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD37">
              <td width="50%">RS-232</td>
              <td width="50%">{{ articleAttributes.FELD37 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD39 ||
            articleAttributes.FELD40 ||
            articleAttributes.FELD41 ||
            articleAttributes.FELD42 ||
            articleAttributes.FELD43 ||
            articleAttributes.FELD44 ||
            articleAttributes.FELD45
          "
        >
          <v-card-title>Bild-Anschlüsse</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD39">
              <td width="50%">VGA</td>
              <td width="50%">{{ articleAttributes.FELD39 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD40">
              <td width="50%">DVI</td>
              <td width="50%">{{ articleAttributes.FELD40 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD41">
              <td width="50%">HDMI</td>
              <td width="50%">{{ articleAttributes.FELD41 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD42">
              <td width="50%">Display Port</td>
              <td width="50%">{{ articleAttributes.FELD42 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD43">
              <td width="50%">Mini-DVI</td>
              <td width="50%">{{ articleAttributes.FELD43 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD44">
              <td width="50%">Mini-HDMI</td>
              <td width="50%">{{ articleAttributes.FELD44 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD45">
              <td width="50%">Mini-Display Port</td>
              <td width="50%">{{ articleAttributes.FELD45 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="articleAttributes.FELD46">
          <v-card-title>Audio-Anschlüsse</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD46">
              <td width="50%">Kopfhörer+Mikrofon (2in1)</td>
              <td width="50%">{{ articleAttributes.FELD46 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="articleAttributes.FELD47 || articleAttributes.FELD48">
          <v-card-title>Einschubkartenslots</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD47">
              <td width="50%">SD-Kartenleser</td>
              <td width="50%">{{ articleAttributes.FELD47 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD48">
              <td width="50%">ExpressCard</td>
              <td width="50%">{{ articleAttributes.FELD48 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD49 ||
            articleAttributes.FELD50 ||
            articleAttributes.FELD51
          "
        >
          <v-card-title>Mobile Verbindungen</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD49">
              <td width="50%">WLAN</td>
              <td width="50%">{{ articleAttributes.FELD49 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD50">
              <td width="50%">Bluetooth</td>
              <td width="50%">{{ articleAttributes.FELD50 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD51">
              <td width="50%">WWAN</td>
              <td width="50%">{{ articleAttributes.FELD51 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD79">
              <td width="50%">SIM-Karten Slot</td>
              <td width="50%">{{ articleAttributes.FELD79 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD52 ||
            articleAttributes.FELD53 ||
            articleAttributes.FELD54 ||
            articleAttributes.FELD55
          "
        >
          <v-card-title>Tastatur</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD52">
              <td width="50%">Layout</td>
              <td width="50%">{{ articleAttributes.FELD52 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD53">
              <td width="50%">Beleuchtung</td>
              <td width="50%">{{ articleAttributes.FELD53 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD54">
              <td width="50%">Ziffernblock</td>
              <td width="50%">{{ articleAttributes.FELD54 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD55">
              <td width="50%">Pointing-Stick</td>
              <td width="50%">{{ articleAttributes.FELD55 }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD95">
              <td width="50%">Sprache</td>
              <td width="50%">{{ articleAttributes.FELD95 }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table
          v-if="
            articleAttributes.FELD38 ||
            articleAttributes.FELD56 ||
            articleAttributes.FELD57 ||
            articleAttributes.FELD58 ||
            articleAttributes.FELD59
          "
        >
          <v-card-title>Sonstiges</v-card-title>

          <tbody>
            <tr v-if="articleAttributes.FELD38">
              <td width="50%">Docking</td>
              <td width="50%">{{ articleAttributes.FELD38 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD56">
              <td width="50%">Webcam integriert</td>
              <td width="50%">{{ articleAttributes.FELD56 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD57">
              <td width="50%">Lautsprecher integriert</td>
              <td width="50%">{{ articleAttributes.FELD57 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD58">
              <td width="50%">Mikrofon integriert</td>
              <td width="50%">{{ articleAttributes.FELD58 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
            <tr v-if="articleAttributes.FELD59">
              <td width="50%">Fingerprint-Sensor</td>
              <td width="50%">{{ articleAttributes.FELD59 == 1 ? "Ja" : "Nein" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click.stop="close">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PostService from "../../PostService";

export default {
  name: "ArticleSpecifications",
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    return {
      loading: false,
      articleAttributes: false,
    };
  },
  props: {
    dialog: {
      type: Boolean,
    },
    articleID: {
      default: false,
    },
  },
  methods: {
    fetchArticleAttributes() {
      this.loading = true;

      this.postService
        .fetchArticleAttributes({
          "filter[ART_ID]==": this.articleID,
        })
        .then((res) => {
          if (res.data && res.data.total === 1) {
            this.articleAttributes = res.data.data[0];

            this.$forceUpdate();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:dialog", false);
    },
  },
  watch: {
    articleID: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.articleAttributes = await this.fetchArticleAttributes();
      }
    },
  },
};
</script>
