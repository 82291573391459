import Vue from 'vue'
import App from './App.vue'

import { store } from './plugins/vuex';
import router from './plugins/router';
import vuetify from './plugins/vuetify';
import filter from './plugins/filter';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './registerServiceWorker'

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  filter,
  beforeCreate() {
    this.$store.commit('userAddress');
  },
  render: h => h(App)
}).$mount('#app')