import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[(this.orderArticles)?_c('ArticleComponents',{attrs:{"dialog":_vm.articleComponentsDialog,"orderID":_vm.order.ID,"articleID":_vm.articleComponentsArticlePOID},on:{"update:dialog":function($event){_vm.articleComponentsDialog=$event},"update:orderID":function($event){return _vm.$set(_vm.order, "ID", $event)},"update:order-i-d":function($event){return _vm.$set(_vm.order, "ID", $event)},"update:articleID":function($event){_vm.articleComponentsArticlePOID=$event},"update:article-i-d":function($event){_vm.articleComponentsArticlePOID=$event}}}):_vm._e(),_c('ArticleSpecifications',{attrs:{"dialog":_vm.articleSpecificationsDialog,"articleID":_vm.articleSpecificationsArticleID},on:{"update:dialog":function($event){_vm.articleSpecificationsDialog=$event},"update:articleID":function($event){_vm.articleSpecificationsArticleID=$event},"update:article-i-d":function($event){_vm.articleSpecificationsArticleID=$event}}}),_c('PDFViewer',{attrs:{"dialog":_vm.PDFViewerDialog,"filename":_vm.PDFViewerFilename,"url":_vm.PDFViewerURL},on:{"update:dialog":function($event){_vm.PDFViewerDialog=$event},"update:filename":function($event){_vm.PDFViewerFilename=$event},"update:url":function($event){_vm.PDFViewerURL=$event}}}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('StatsCard',{attrs:{"icon":"mdi-pound","color":"#FF5722","headline":"Serviceantragsnummer","text":("S" + (this.orderWarranty.id)),"loading":!Object.keys(_vm.orderWarranty).includes('id')}})],1),_c(VCol,{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('StatsCard',{attrs:{"icon":"mdi-package-variant-closed","color":"#009688","headline":"Auftragsnummer","text":("" + (this.orderWarranty.order_number)),"to":Object.keys(_vm.order).includes('ID') ? ("/orders/" + (_vm.order.ID)) : '',"loading":!Object.keys(_vm.order).includes('ID')}})],1)],1),(
      Object.keys(_vm.orderWarranty).includes('description') &&
      _vm.orderWarranty.description
    )?_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"outlined":"","height":"100%","loading":!Object.keys(_vm.orderWarranty).includes('description')}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Kommentar")]),_c(VDivider),(Object.keys(_vm.orderWarranty).includes('description'))?_c(VCardText,{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.orderWarranty.description))]):_vm._e()],1)],1)],1):_vm._e(),_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"outlined":"","loading":!Object.keys(_vm.orderWarrantyAttachments).includes('total')}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Anhänge")]),_c(VDivider),_c(VListItem,[_c(VListItemContent,[_c(VList,{attrs:{"two-line":""}},[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();(_vm.PDFViewerDialog = true),
                  (_vm.PDFViewerFilename = "Serviceantragsschein"),
                  (_vm.PDFViewerURL = "https://apiv2.nexchance.de/pdf/warranty/" + (_vm.orderWarranty.id) + "/view")}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Serviceantragsschein")]),_c(VListItemSubtitle,[_vm._v("Drucken Sie Ihr Retourenschein aus und legen Sie ihn dem Paket bei")])],1),_c(VListItemAction,[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();(_vm.PDFViewerDialog = true),
                          (_vm.PDFViewerFilename = "Serviceantragsschein"),
                          (_vm.PDFViewerURL = "https://apiv2.nexchance.de/pdf/warranty/" + (_vm.orderWarranty.id) + "/view")}}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Serviceantragsschein")])],1)],1)],1)],1)],1)],1)],1),_vm._l((_vm.orderWarrantyAttachments.data),function(attachment){return _c(VList,{key:attachment.id},[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();(_vm.PDFViewerDialog = true),
                  (_vm.PDFViewerFilename = "" + (_vm.$options.filters.extractFileName(
                    attachment.name
                  ))),
                  (_vm.PDFViewerURL = "https://apiv2.nexchance.de/system/order/warranty/attachments/" + (attachment.id) + "/view")}}},[_c(VListItemAvatar,[(attachment.parcel_url)?_c(VIcon,{attrs:{"color":attachment.parcel_received_at ? 'green' : 'orange'}},[_vm._v("mdi-truck")]):_c(VIcon,[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("extractFileName")(attachment.name)))]),_c(VListItemSubtitle,[_vm._v(_vm._s(attachment.description))])],1),_c(VListItemAction,[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();(_vm.PDFViewerDialog = true),
                          (_vm.PDFViewerFilename = "" + (_vm.$options.filters.extractFileName(
                            attachment.name
                          ))),
                          (_vm.PDFViewerURL = "https://apiv2.nexchance.de/system/order/warranty/attachments/" + (attachment.id) + "/view")}}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("extractFileName")(attachment.name)))])],1)],1),(attachment.parcel_url)?_c(VListItem,{attrs:{"href":attachment.parcel_url,"target":"_blank"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-truck")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Sendungsverfolgung")])],1)],1):_vm._e()],1)],1)],1)],1)],1)})],2)],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"secondary","link":"","loading":_vm.postOrderWarrantyLabelLoading},on:{"click":function($event){$event.stopPropagation();return _vm.postOrderWarrantyLabel.apply(null, arguments)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Retourenlabel erstellen ")],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"outlined":"","loading":!Object.keys(_vm.orderWarrantyArticles).includes('0')}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Artikel")]),_c(VDivider),(_vm.orderWarrantyArticles)?_c(VListItem,[_c(VListItemContent,[_c(VList,{attrs:{"two-line":""}},_vm._l((_vm.orderWarrantyArticles),function(orderWarrantyArticle){return _c(VListItem,{key:orderWarrantyArticle.id,attrs:{"link":""}},[_c(VListItemAvatar,{attrs:{"tile":""}},[_c(VAvatar,{attrs:{"tile":""}},[(
                        !_vm.orderArticlesImages[
                        orderWarrantyArticle.BEP.ART_ID
                        ] ||
                        !_vm.orderArticlesImages[
                        orderWarrantyArticle.BEP.ART_ID
                        ][0]
                      )?_c(VIcon,[_vm._v("mdi-camera")]):_c(VImg,{attrs:{"src":((_vm.config.hostname) + "/vario/images/" + (_vm.orderArticlesImages[
                      orderWarrantyArticle.BEP.ART_ID
                    ][0].ID) + "/view"),"alt":_vm.orderArticlesImages[
    orderWarrantyArticle.BEP.ART_ID
  ][0].DATEINAME,"contain":""}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(orderWarrantyArticle.BEP.LANGTEXT))]),_c(VListItemSubtitle,[_vm._v("Artikelnummer: "+_vm._s(orderWarrantyArticle.BEP.ARTIKELNR)+" | Menge: "+_vm._s(_vm._f("formatInt")(orderWarrantyArticle.MENGE)))])],1),(orderWarrantyArticle.BEP.ARTIKELART == 5)?_c(VListItemAction,[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();(_vm.articleSpecificationsDialog = true),
                          (_vm.articleSpecificationsArticleID =
                            orderWarrantyArticle.BEP.ART_ID)}}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-view-module")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Technische Daten")])],1)],1),_c(VListItem,{on:{"click":function($event){$event.stopPropagation();(_vm.articleComponentsDialog = true),
(_vm.articleComponentsArticlePOID =
  orderWarrantyArticle.BEP.ID)}}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-screwdriver")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Komponenten")])],1)],1)],1)],1)],1):_vm._e()],1)}),1)],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }