import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.input,"solo-inverted":"","flat":"","hide-details":"","hide-no-data":"","hide-selected":"","return-object":"","item-text":"description","label":"Suche","chips":"","prepend-inner-icon":"mdi-magnify","color":"#FAFAFA","background-color":"blue-grey lighten-2"},on:{"update:searchInput":function($event){_vm.input=$event},"update:search-input":function($event){_vm.input=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"input-value":data.selected},on:{"click":data.select}},'v-chip',data.attrs,false),[_c(VAvatar,{attrs:{"left":""}},[_c(VIcon,{attrs:{"color":data.item.color}},[_vm._v(_vm._s(data.item.icon))])],1),_vm._v(" "+_vm._s(data.item.description)+" ")],1)]}},{key:"item",fn:function(data){return [_c(VListItemAction,[_c(VIcon,{attrs:{"color":data.item.color}},[_vm._v(_vm._s(data.item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{attrs:{"to":data.item.url},domProps:{"textContent":_vm._s(data.item.description)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(data.item.type)}})],1),_c(VListItemAction,[_vm._v(" "+_vm._s(data.item.descriptionRight)+" ")])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }