<template>
  <v-main>
    <v-container fluid>
      <v-card v-if="marketOffers" outlined>
        <v-card-title class="headline">{{ this.$store.getters.title }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-text-field class="mr-5" width="1" v-model="differenceModal" single-line
            hint="Übernahme von Preisen mit max. VK Differenz" label="VK Differenz">
          </v-text-field>

          <v-btn color="secondary" :disabled="btnGetAllDisabled" :loading="btnGetAllLoading"
            @click="patchArticlePriceAll">Alle Übernehmen</v-btn>
        </v-card-actions>

        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SKU</th>
                <th class="text-left">Name</th>
                <th class="text-left">Markt</th>
                <th class="text-left">VK</th>
                <th class="text-left">VK Empf.</th>
                <th class="text-left">VK Diff.</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in marketOffers.market_offers" :key="item.id.market_code + '-' + item.id.offer_id.id">
                <td>{{ item.sku }}</td>
                <td>{{ item.instance_name }}</td>
                <td>{{ item.id.market_code.toUpperCase() }}</td>
                <td>{{ item.market_price.price | formatNumberPrettify }} {{
                  item.market_price.currency_code
                }}</td>
                <td>
                  {{
                    item.market_price.instance_buybox_info
                      .suggested_offer_price | formatNumberPrettify
                  }}
                  {{ item.market_price.currency_code }}
                </td>
                <td>
                  {{
                    item.market_price.instance_buybox_info
                      .suggested_offer_price_diff | formatNumberPrettify
                  }}
                  {{ item.market_price.currency_code }}
                </td>
                <td class="text-right">
                  <v-btn depressed color="secondary" :disabled="item.disabled" :loading="item.loading"
                    @click="patchArticlePrice(item)">Übernehmen</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";

export default {
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.previousDate = false;

    return {
      differenceModal: 5,
      marketOffers: false,
      btnGetAllDisabled: false,
      btnGetAllLoading: false,
    };
  },
  methods: {
    fetchRefurbedBuyBox(country) {
      this.$store.commit("loading", true);

      this.postService
        .fetchRefurbedBuyBox(country)
        .then((res) => {
          if (res.data) {
            this.marketOffers = res.data;

            if (this.marketOffers.market_offers.length === 0) {
              this.btnGetAllDisabled = true;
            } else {
              this.marketOffers.market_offers =
                this.marketOffers.market_offers.filter(function (element) {
                  if (element.market_price.instance_buybox_info
                    .suggested_offer_price_diff > 0 && element.sku.length !== 13) {
                    return element;
                  }
                }).map((element) => ({
                  ...element,
                  disabled: false,
                  loading: false,
                }));
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    patchArticlePrice(item) {
      item.loading = true;
      let params = {};

      let price = {
        gross:
          item.market_price.instance_buybox_info.suggested_offer_price,
        net:
          (item.market_price.instance_buybox_info.suggested_offer_price / 119) * 100,
      };

      if (item.id.market_code == "de") {
        params = {
          VK16: price.net,
          VK16BRUTTO: price.gross,
        };
      } else if (item.id.market_code == "at") {
        params = {
          VK17: price.net,
          VK17BRUTTO: price.gross,
        }
      }

      this.postService.UpdateMarketOffer(item);

      this.postService
        .patchArticlePrice(item.sku, params)
        .then((res) => {
          if (res.data) {
            item.disabled = true;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          item.loading = false;
        });
    },
    patchArticlePriceAll() {
      if (!isNaN(this.differenceModal)) {
        this.btnGetAllLoading = true;

        this.marketOffers.market_offers.forEach((item) => {
          if (
            item.loading === false &&
            item.disabled === false &&
            item.market_price.instance_buybox_info.suggested_offer_price_diff <=
            this.differenceModal
          ) {
            this.patchArticlePrice(item);
          }
        });

        this.btnGetAllLoading = false;
      }
    },
  },
  mounted() {
    this.$store.commit("title", this.$route.meta.title);

    this.fetchRefurbedBuyBox(["de", "at"]);
  },
};
</script>