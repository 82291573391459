<template>
  <v-card
    height="100%"
    outlined
    :loading="loading"
    :link="typeof to !== 'undefined' || typeof href !== 'undefined'"
    :hover="typeof to !== 'undefined' || typeof href !== 'undefined'"
    :href="href"
    :target="target"
    :to="to"
  >
    <v-row align="center" justify="center" style="margin: 0; height: 100%">
      <v-col align="center">
        <v-icon size="80" :color="color">{{ icon }}</v-icon>
      </v-col>
      <v-col
        align="center"
        :style="`color: ${textColor}; background: ${color}; border-radius: 0 4px 4px 0; height: 100%;`"
      >
        <v-row>
          <v-col class="headline">{{ getText() }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ headline }}</v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import colors from "vuetify/lib/util/colors";

export default {
  name: "ArticleComponents",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#424242",
    },
    textColor: {
      type: String,
      required: false,
      default: colors.shades.white,
    },
    headline: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getText() {
      if (this.text.includes("undefined") || this.text === "false") {
        return "-";
      }

      return this.text;
    },
  },
};
</script>
