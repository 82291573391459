import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        title: '',
        loading: false,
        version: false,
        user: {
            address: false
        }
    },
    mutations: {
        loading(state, loading) {
            state.loading = loading;
        },
        version(state, version) {
            state.version = version;
        },
        title(state, title) {
            state.title = title;
        },
        userAddress(state) {
            if (typeof localStorage.address !== "undefined") {
                state.user.address = JSON.parse(localStorage.getItem('address'));

            }
            else {
                state.user.address = false;
            }
        }
    },
    getters: {
        loading: state => state.loading,
        version: state => state.version,
        title: state => state.title,
        user: state => state.user
    }
});

export default store;