import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c('Alert',{attrs:{"snackbar":_vm.alertComponent,"text":_vm.alertComponentText},on:{"update:snackbar":function($event){_vm.alertComponent=$event},"update:text":function($event){_vm.alertComponentText=$event}}}),_c(VContainer,{staticClass:"fill-height"},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"color":_vm.$vuetify.theme.themes.light.navbar,"dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Anmelden")])],1),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VCardText,[_c(VTextField,{attrs:{"error-messages":_vm.emailErrors,"label":"E-Mail Adresse","name":"email","prepend-icon":"mdi-at","type":"email","required":""},on:{"input":function($event){return _vm.$v.email.$touch()},"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"error-messages":_vm.passwordErrors,"label":"Passwort","name":"password","prepend-icon":"mdi-lock","type":"password","required":""},on:{"input":function($event){return _vm.$v.password.$touch()},"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,{staticClass:"pb-3"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v("Anmelden")])],1)],1)],1),_c('p',{staticClass:"text-center mt-5"},[_vm._v(" neXchance Kundenportal "+_vm._s(this.$store.getters.version)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }