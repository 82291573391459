<template>
  <v-dialog
    @input="close"
    :value="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click.stop="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ filename }}</v-toolbar-title>
    </v-toolbar>

    <embed width="100%" style="height: calc(100% - 64px);" :src="url" :key="url" />
  </v-dialog>
</template>

<script>
export default {
  name: "PDFViewer",

  props: {
    dialog: {
      type: Boolean
    },
    filename: {
      default: false
    },
    url: {
      default: false
    }
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    }
  }
};
</script>