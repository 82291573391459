<template>
  <v-main>
    <Alert :snackbar.sync="alertComponent" :text.sync="alertComponentText" />
    <v-container class="fill-height">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar :color="$vuetify.theme.themes.light.navbar" dark flat>
              <v-toolbar-title>Anmelden</v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" @submit.prevent="login">
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  label="E-Mail Adresse"
                  name="email"
                  prepend-icon="mdi-at"
                  type="email"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :error-messages="passwordErrors"
                  label="Passwort"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pb-3">
                <v-spacer />
                <v-btn color="primary" type="submit">Anmelden</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

          <p class="text-center mt-5">
            neXchance Kundenportal {{ this.$store.getters.version }}
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";
import Alert from "../components/Alert";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

const postService = new PostService();

export default {
  components: {
    Alert,
  },

  mixins: [validationMixin],

  validations: {
    email: { required, email },
    password: { required, minLength: minLength(3) },
  },

  data() {
    this.$store.commit("title", "Login");

    return {
      email: "",
      password: "",

      alertComponent: false,
      alertComponentText: "",
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Ungültige E-Mail Adresse");
      !this.$v.email.required && errors.push("E-Mail erforderlich");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.password.minLength && errors.push("Zu kurzes Passwort");
      !this.$v.password.required && errors.push("Passwort erforderlich");
      return errors;
    },
  },
  methods: {
    login(event) {
      event.preventDefault();

      this.$v.$touch();

      if (!this.emailErrors.length && !this.passwordErrors.length) {
        this.$store.commit("loading", true);

        postService
          .Login(this.email, this.password)
          .then((res) => {
            if (res.data && res.data.status === "success") {
              localStorage.setItem("address", JSON.stringify(res.data.data));

              this.$store.commit("userAddress", res.data.data);

              this.$router.push("/");
            } else {
              this.alertComponent = true;
              this.alertComponentText =
                "Ungültige E-Mail Adresse oder Passwort";
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.$store.commit("loading", false);
          });
      }
    },
  },
};
</script>