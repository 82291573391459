import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{attrs:{"app":"","clipped-left":"","color":_vm.$vuetify.theme.themes.light.navbar}},[_c(VAppBarNavIcon,{staticClass:"white--text",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('span',{staticClass:"title ml-3 mr-5 d-none d-md-flex white--text"},[_vm._v(" neXchance "),_c('span',{staticClass:"font-weight-light pl-1 white--text"},[_vm._v("Kundenportal")])]),_c('Search'),(
      _vm.$store.getters.user.address !== false &&
      this.$store.getters.user.address.ID === 100001
    )?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,{attrs:{"color":"#FAFAFA"}},[_vm._v("mdi-alert-decagram")])],1)]}}],null,false,2739293259)},[_c(VList,[_c(VListItem,{attrs:{"to":"/alerts/replication/orders"}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"#009688"}},[_vm._v("mdi-package-variant-closed")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Bestellungen")])],1)],1),_c(VListItem,{attrs:{"to":"/alerts/replication/articles"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-laptop")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Artikel")])],1)],1),_c(VListItem,{attrs:{"to":"/alerts/replication/addresses"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-account-alert-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Adressen")])],1)],1)],1)],1):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.$store.getters.user.address !== false)?{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","to":"/logout"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"#FAFAFA"}},[_vm._v("mdi-logout")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Abmelden")])])],1),_c(VNavigationDrawer,{attrs:{"app":"","clipped":"","color":_vm.$vuetify.theme.themes.light.background},scopedSlots:_vm._u([(_vm.$store.getters.user.address !== false)?{key:"append",fn:function(){return [_c(VDivider),_c(VList,[_c(VListItem,[_c(VListItemAction,[_c(VImg,{attrs:{"width":"20","src":require("@/assets/logo.png"),"alt":"neXchance IT GmbH","contain":""}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$store.getters.version))])],1)],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[(_vm.$store.getters.user.address !== false)?_c(VListItem,{attrs:{"to":"/"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-view-dashboard")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Dashboard")])],1)],1):_vm._e(),(_vm.$store.getters.user.address !== false)?_c(VListItem,{attrs:{"color":"#009688","to":"/orders"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-package-variant-closed")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Bestellungen")])],1)],1):_vm._e(),(
        _vm.$store.getters.user.address !== false &&
        this.$store.getters.user.address.PGR_ID !== 3
      )?_c(VListGroup,{attrs:{"value":false,"color":"#FF5722","prepend-icon":"mdi-hammer-wrench","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v("Serviceanträge")])]},proxy:true}],null,false,1555098133)},[_c(VListItem,{attrs:{"to":"/warranty","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Übersicht")])],1)],1),_c(VListItem,{attrs:{"to":"/warranty/add","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Erstellen")])],1)],1)],1):_vm._e(),(
        _vm.$store.getters.user.address !== false &&
        this.$store.getters.user.address.PGR_ID !== 3
      )?_c(VListGroup,{attrs:{"value":false,"color":"#3F51B5","prepend-icon":"mdi-truck-delivery-outline","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v("Retouren")])]},proxy:true}],null,false,924634503)},[_c(VListItem,{attrs:{"to":"/return","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Übersicht")])],1)],1),_c(VListItem,{attrs:{"to":"/return/add","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Erstellen")])],1)],1)],1):_vm._e(),(
        _vm.$store.getters.user.address !== false &&
        this.$store.getters.user.address.ID === 100001
      )?_c(VListGroup,{attrs:{"value":false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v("Refurbed")])]},proxy:true}],null,false,3555006514)},[_c(VListItem,{attrs:{"to":"/refurbed/buy-box","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("BuyBox")])],1)],1),_c(VListItem,{attrs:{"to":"/refurbed/buy-box-reset","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("BuyBox zurücksetzen")])],1)],1),_c(VListItem,{attrs:{"to":"/refurbed/offer/import","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Angebote importieren")])],1)],1),_c(VListItem,{attrs:{"to":"/refurbed/offer/import-new","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Angebote importieren NEU")])],1)],1)],1):_vm._e(),(_vm.$store.getters.user.address === false)?_c(VListItem,{attrs:{"to":"/login"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-login")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Login")])],1)],1):_vm._e()],1)],1),_c(VSnackbar,{attrs:{"multi-line":true,"timeout":"10000","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){_vm.snackbarModel = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbarModel),callback:function ($$v) {_vm.snackbarModel=$$v},expression:"snackbarModel"}},_vm._l((_vm.snackbarText),function(result,index){return _c('div',{key:index},[(result)?_c('div',[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(index)+" ")],1):_c('div',[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(index)+" ")],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }