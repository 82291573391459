<template>
  <v-main>
    <v-container>
      <v-card outlined>
        <v-card-title class="headline">Serviceanträge</v-card-title>
        <v-divider></v-divider>

        <v-list
          two-line
          v-for="warrantyOrder in OrderWarrantyList.data"
          :key="warrantyOrder.id"
          class="pt-0 pb-0"
        >
          <v-list-item link :to="{ path: `/warranty/${warrantyOrder.id}` }">
            <v-list-item-content>
              <v-list-item-title>S{{ warrantyOrder.id }}</v-list-item-title>
              <v-list-item-subtitle
                >Auftragsnummer: #{{
                  warrantyOrder.order_number
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
        </v-list>
      </v-card>

      <div class="text-center mt-3">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.total"
          :total-visible="20"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";

export default {
  data() {
    this.$store.commit("title", "Serviceanträge");

    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.previousDate = false;

    return {
      OrderWarrantyList: false,
      pagination: {
        page: 1,
        offset: 0,
        limit: 20,
        total: 0,
      },
    };
  },
  methods: {
    fetchOrdersWarranty() {
      this.$store.commit("loading", true);

      this.postService
        .fetchOrdersWarranty({
          "limit=": this.pagination.limit,
          "offset=": this.pagination.offset,
          "order=": "id",
          "direction=": "DESC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.OrderWarrantyList = res.data;

            this.pagination.total = Math.floor(
              res.data.total / this.pagination.limit
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    changePage(page) {
      this.pagination.page = page;
      this.pagination.offset = this.pagination.limit * this.pagination.page;

      this.fetchOrdersWarranty();
    },
  },
  mounted() {
    this.fetchOrdersWarranty();
  },
};
</script>
