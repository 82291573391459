<template>
  <v-main>
    <v-container>
      <v-form ref="form" @submit.prevent="create">
        <v-card outlined>
          <v-card-title class="headline">Retoure erstellen</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="orderModel"
                  :error-messages="orderErrors"
                  :items="orderItems"
                  :loading="orderIsLoading"
                  :search-input.sync="orderInput"
                  hide-no-data
                  label="Auftragsnummer / Bestellnummer"
                  prepend-icon="mdi-pound"
                  return-object
                  required
                  item-value="ID"
                  item-text="title"
                  @input="$v.orderModel.$touch()"
                  @blur="$v.orderModel.$touch()"
                >
                  <template v-slot:item="data">
                    <v-list-item-action>
                      <v-icon color="#009688"
                        >mdi-package-variant-closed</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="data.item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="`Zustellungsdatum: ${data.item.S01} Uhr`"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      {{ data.item.S02 }} Tage
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col>
                <v-select
                  v-model="reasonModel"
                  :error-messages="reasonErrors"
                  :items="reasonItems"
                  label="Grund der Retoure"
                  @input="$v.reasonModel.$touch()"
                  @blur="$v.reasonModel.$touch()"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-model="descriptionModel"
                  :error-messages="descriptionErrors"
                  label="Beschreibung des Fehlers"
                  auto-grow
                  prepend-icon="mdi-comment-edit-outline"
                  @input="$v.descriptionModel.$touch()"
                  @blur="$v.descriptionModel.$touch()"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          outlined
          class="mt-5"
          v-if="
            $store.getters.user.address !== false &&
            this.$store.getters.user.address.ID === 100001
          "
        >
          <v-card-title class="headline">Externer Dienst</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  v-model="extServiceModel"
                  :items="extServiceItems"
                  label="Dienst"
                  return-object
                  item-value="name"
                  item-text="text"
                  :clearable="!extServiceReferenceDisabled"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-img :src="item.image" :alt="item.text" contain />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="extServiceReferenceModel"
                  :error-messages="extServiceReferenceErrors"
                  label="Referenz"
                  auto-grow
                  :disabled="extServiceReferenceDisabled"
                  @input="$v.extServiceReferenceModel.$touch()"
                  @blur="$v.extServiceReferenceModel.$touch()"
                  :required="!extServiceReferenceDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-5" :loading="orderArticlesIsLoading">
          <v-card-title class="headline">Artikel auswählen</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row v-if="orderArticlesErrors">
              <v-col
                v-for="(orderArticlesError, index) in orderArticlesErrors"
                :key="index"
              >
                <v-alert type="error">{{ orderArticlesError }}</v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                  v-model="orderArticlesModel"
                  :headers="orderArticlesHeader"
                  :items="orderArticlesItems"
                  item-key="ID"
                  no-data-text="Wählen Sie zuerst eine Auftragsnummer aus"
                  show-select
                  hide-default-header
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`item.MENGE`]="{ item }">
                    <v-select
                      v-model="item.MENGE"
                      :items="QuantityItems(item)"
                      :suffix="item.ME"
                    ></v-select>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="mt-3 text-right">
          <v-col>
            <v-btn type="submit" color="primary"> Absenden </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import PostService from "../PostService";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "OrderReturnAdd",

  mixins: [validationMixin],

  validations: {
    orderModel: { required },
    orderArticlesModel: { required },
    reasonModel: { required },
    extServiceReferenceModel: { required },
    descriptionModel: { required },
  },
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.config = config;

    return {
      // Order
      orderModel: null,
      orderItems: [],
      orderIsLoading: false,
      orderInput: null,

      // Order Articles
      orderArticlesModel: [],
      orderArticlesItems: [],
      orderArticlesIsLoading: false,
      orderArticlesInput: null,
      orderArticlesDisabled: true,
      orderArticlesHeader: [
        { text: "Artikelnummer", value: "ARTIKELNR" },
        { text: "Bezeichnung", value: "LANGTEXT" },
        { text: "Menge", value: "MENGE" },
      ],

      // Reason
      reasonModel: "Retoure",
      reasonItems: [{ text: "Retoure" }],

      // External services
      extServiceModel: null,
      extServiceItems: [
        {
          name: "refurbed",
          text: "Refurbed",
          image: require("@/assets/stores/refurbed.png"),
        },
      ],

      // External services reference
      extServiceReferenceModel: null,
      extServiceReferenceDisabled: true,

      // Decription
      descriptionModel: null,
    };
  },
  props: {
    dialog: {
      type: Boolean,
    },
  },
  methods: {
    create(event) {
      event.preventDefault();

      this.$v.$touch();

      if (
        !this.orderErrors.length &&
        !this.orderArticlesErrors.length &&
        !this.reasonErrors.length &&
        !this.descriptionErrors.length &&
        !this.extServiceReferenceErrors.length
      ) {
        this.$store.commit("loading", true);

        this.postService
          .createOrderReturn(
            this.orderModel.BELEGNR,
            this.reasonModel,
            this.descriptionModel,
            this.extServiceModel?.name ?? this.extServiceModel,
            this.extServiceReferenceModel,
            this.orderArticlesModel
          )
          .then((res) => {
            if (res.data.code === 201) {
              this.$router.push(`/return/${res.data.data.id}`);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.$store.commit("loading", true);
          });
      }
    },
    QuantityItems(item) {
      if (typeof item !== "undefined" && Object.keys(item).includes("MENGE")) {
        let QuantityItems = [];

        for (let i = 1; i <= this.$options.filters.formatInt(item.MENGE); i++) {
          QuantityItems.push({
            text: i,
            value: `${i}.0000`,
          });
        }

        return QuantityItems;
      }

      return 0;
    },
    fetchReturnReceiptOrders(val) {
      this.orderIsLoading = true;

      return this.postService
        .fetchReturnReceiptOrders({
          "filter[AUFTRAGSNR]=": val,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "order=": "BELEGDATUM",
          "direction=": "ASC",
          "limit=": "20",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            res.data.data.map((item) => {
              item.title = item.AUFTRAGSNR;

              this.orderItems.push(item);

              return item;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.orderIsLoading = false;
        });
    },
    fetchReturnReceiptOrdersByBetreff(val) {
      this.orderIsLoading = true;

      return this.postService
        .fetchReturnReceiptOrders({
          "filter[BETREFF]=": val,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "order=": "BELEGDATUM",
          "direction=": "ASC",
          "limit=": "20",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            res.data.data.map((item) => {
              item.title = item.BETREFF;

              this.orderItems.push(item);

              return item;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.orderIsLoading = false;
        });
    },
    fetchOrderArticles() {
      if (this.orderArticlesIsLoading) return;

      this.orderArticlesItems = [];
      this.orderArticlesModel = [];
      this.orderArticlesIsLoading = true;
      this.orderArticlesDisabled = true;

      return this.postService
        .fetchOrderArticles({
          "filter[BEK_ID]==": this.orderModel.ID,
          "filter[PARENT_ID]=": null,
          "order=": "PO",
          "direction=": "ASC",
          "limit=": "20",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderArticlesItems = res.data.data;
            this.orderArticlesModel = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.orderArticlesIsLoading = false;
          this.orderArticlesDisabled = false;
        });
    },
    validateExtServiceReference() {
      if (this.extServiceModel && this.extServiceReferenceModel) {
        // Refurbed: Check if valid UUID
        if (this.extServiceModel.name === "refurbed") {
          let s = this.extServiceReferenceModel;

          s = s.match(
            "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
          );

          if (s !== null) {
            return true;
          }
        }
      }

      return false;
    },
  },
  computed: {
    orderErrors() {
      const errors = [];
      if (!this.$v.orderModel.$dirty) return errors;
      !this.$v.orderModel.required &&
        errors.push("Auftragsnummer erforderlich");
      return errors;
    },
    orderArticlesErrors() {
      const errors = [];
      if (!this.$v.orderArticlesModel.$dirty) return errors;
      !this.$v.orderArticlesModel.required &&
        errors.push(
          "Wählen Sie bitte mindestens einen Artikel für die Retoure aus"
        );
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.reasonModel.$dirty) return errors;
      !this.$v.reasonModel.required &&
        errors.push("Grund der Retoure erforderlich");
      return errors;
    },
    extServiceReferenceErrors() {
      const errors = [];

      if (!this.$v.extServiceReferenceModel.$dirty) return errors;
      !this.extServiceReferenceDisabled &&
        !this.$v.extServiceReferenceModel.required &&
        errors.push("Referenznummer erforderlich");
      !this.extServiceReferenceDisabled &&
        !this.validateExtServiceReference() &&
        errors.push("Ungültige Referenz");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.descriptionModel.$dirty) return errors;
      return errors;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      let fetchReturnReceiptOrders = this.fetchReturnReceiptOrders(
        this.$route.params.id
      );

      Promise.all([fetchReturnReceiptOrders]).then(() => {
        this.orderModel = this.orderItems[0];
      });
    }
  },
  watch: {
    orderModel: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchOrderArticles();
      }
    },
    orderInput: function (newVal, oldVal) {
      if (newVal.length >= 3 && newVal !== oldVal) {
        this.fetchReturnReceiptOrders(newVal);
        this.fetchReturnReceiptOrdersByBetreff(newVal);
      }
    },
    extServiceModel: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.extServiceReferenceModel = null;

        if (newVal !== null) {
          this.extServiceReferenceDisabled = false;
        } else {
          this.extServiceReferenceDisabled = true;
        }
      }
    },
    extServiceReferenceModel: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal !== null) {
          this.extServiceReferenceModel = this.extServiceReferenceModel.trim();
        }
      }
    },
  },
};
</script>
