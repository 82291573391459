import Vue from 'vue';
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,

        themes: {
            light: {
                primary: '#99CC00',
                navbar: colors.blueGrey.darken2,
                background: colors.grey.lighten4
            }
        }
    }
});