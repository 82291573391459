<template>
  <v-main>
    <ArticleComponents v-if="this.orderArticles" :dialog.sync="articleComponentsDialog" :orderID.sync="order.ID"
      :articleID.sync="articleComponentsArticlePOID" />
    <ArticleSpecifications :dialog.sync="articleSpecificationsDialog"
      :articleID.sync="articleSpecificationsArticleID" />
    <PDFViewer :dialog.sync="PDFViewerDialog" :filename.sync="PDFViewerFilename" :url.sync="PDFViewerURL" />

    <v-container>
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <StatsCard icon="mdi-pound" color="#3F51B5" headline="Retourennummer" :text="`R${this.orderReturn.id}`"
            :loading="!Object.keys(orderReturn).includes('id')" />
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <StatsCard icon="mdi-package-variant-closed" color="#009688" headline="Auftragsnummer"
            :text="`${this.orderReturn.order_number}`"
            :to="Object.keys(order).includes('ID') ? `/orders/${order.ID}` : ''"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>
      </v-row>

      <v-row v-if="
        Object.keys(orderReturn).includes('description') &&
        orderReturn.description
      ">
        <v-col>
          <v-card outlined height="100%" :loading="!Object.keys(orderReturn).includes('description')">
            <v-card-title class="headline">Kommentar</v-card-title>
            <v-divider></v-divider>

            <v-card-text v-if="Object.keys(orderReturn).includes('description')" style="white-space: pre-line">{{
              orderReturn.description
            }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined :loading="!Object.keys(orderReturnAttachments).includes('total')">
            <v-card-title class="headline">Anhänge</v-card-title>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list two-line>
                  <v-list-item @click.stop="
                    (PDFViewerDialog = true),
                    (PDFViewerFilename = `Retourenschein`),
                    (PDFViewerURL = `https://apiv2.nexchance.de/pdf/return/${orderReturn.id}/view`)
                  ">
                    <v-list-item-avatar>
                      <v-icon color="red">mdi-file-document</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Retourenschein</v-list-item-title>
                      <v-list-item-subtitle>Drucken Sie Ihr Retourenschein aus und legen Sie ihn
                        dem Paket bei</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (PDFViewerDialog = true),
                            (PDFViewerFilename = `Retourenschein`),
                            (PDFViewerURL = `https://apiv2.nexchance.de/pdf/return/${orderReturn.id}/view`)
                          ">
                            <v-list-item-action>
                              <v-icon color="red">mdi-file-document</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Retourenschein</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-list v-for="attachment in orderReturnAttachments.data" :key="attachment.id">
                  <v-list-item @click.stop="
                    (PDFViewerDialog = true),
                    (PDFViewerFilename = `${$options.filters.extractFileName(
                      attachment.name
                    )}`),
                    (PDFViewerURL = `https://apiv2.nexchance.de/system/order/return/attachments/${attachment.id}/view`)
                  ">
                    <v-list-item-avatar>
                      <v-icon v-if="attachment.parcel_url" :color="
                        attachment.parcel_received_at ? 'green' : 'orange'
                      ">mdi-truck</v-icon>
                      <v-icon v-else>mdi-file-document</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        attachment.name | extractFileName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        attachment.description
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (PDFViewerDialog = true),
                            (PDFViewerFilename = `${$options.filters.extractFileName(
                              attachment.name
                            )}`),
                            (PDFViewerURL = `https://apiv2.nexchance.de/system/order/return/attachments/${attachment.id}/view`)
                          ">
                            <v-list-item-action>
                              <v-icon color="red">mdi-file-document</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                attachment.name | extractFileName
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="attachment.parcel_url" :href="attachment.parcel_url" target="_blank">
                            <v-list-item-action>
                              <v-icon>mdi-truck</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Sendungsverfolgung</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn block color="secondary" link @click.stop="postOrderReturnLabel"
                :loading="postOrderReturnLabelLoading">
                <v-icon left> mdi-plus </v-icon>
                Retourenlabel erstellen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined :loading="!Object.keys(orderReturnArticles).includes('0')">
            <v-card-title class="headline">Artikel</v-card-title>
            <v-divider></v-divider>

            <v-list-item v-if="orderReturnArticles">
              <v-list-item-content>
                <v-list two-line>
                  <v-list-item link v-for="orderReturnArticle in orderReturnArticles" :key="orderReturnArticle.id">
                    <v-list-item-avatar tile>
                      <v-avatar tile>
                        <v-icon v-if="
                          !orderArticlesImages[
                          orderReturnArticle.BEP.ART_ID
                          ] ||
                          !orderArticlesImages[
                          orderReturnArticle.BEP.ART_ID
                          ][0]
                        ">mdi-camera</v-icon>

                        <v-img v-else :src="`${config.hostname}/vario/images/${orderArticlesImages[
                        orderReturnArticle.BEP.ART_ID
                      ][0].ID
                        }/view`" :alt="
    orderArticlesImages[
      orderReturnArticle.BEP.ART_ID
    ][0].DATEINAME
  " contain />
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        orderReturnArticle.BEP.LANGTEXT
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Artikelnummer: {{ orderReturnArticle.BEP.ARTIKELNR }} |
                        Menge:
                        {{
                          orderReturnArticle.MENGE | formatInt
                        }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="orderReturnArticle.BEP.ARTIKELART == 5">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (articleSpecificationsDialog = true),
                            (articleSpecificationsArticleID =
                              orderReturnArticle.BEP.ART_ID)
                          ">
                            <v-list-item-action>
                              <v-icon>mdi-view-module</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Technische Daten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click.stop="
  (articleComponentsDialog = true),
  (articleComponentsArticlePOID =
    orderReturnArticle.BEP.ID)
                          ">
                            <v-list-item-action>
                              <v-icon>mdi-screwdriver</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Komponenten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import PostService from "../PostService";
import ArticleComponents from "../components/Order/Article/Components";
import ArticleSpecifications from "../components/Article/Specifications";
import StatsCard from "../components/StatsCard";
import PDFViewer from "../components/PDFViewer";

export default {
  components: {
    ArticleComponents,
    ArticleSpecifications,
    StatsCard,
    PDFViewer,
  },
  data() {
    this.$store.commit("title", "Retourendetails");

    this.config = config;

    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );
    return {
      orderReturn: new Object(),
      orderReturnArticles: new Object(),
      orderReturnAttachments: new Object(),

      order: new Object(),
      orderArticles: new Object(),
      orderArticlesImages: new Array(),

      articleComponentsDialog: false,
      articleComponentsArticlePOID: false,

      articleSpecificationsDialog: false,
      articleSpecificationsArticleID: false,

      PDFViewerDialog: false,
      PDFViewerFilename: false,
      PDFViewerURL: false,

      postOrderReturnLabelLoading: false
    };
  },
  methods: {
    fetchOrderReturn(id) {
      this.$store.commit("loading", true);

      return this.postService
        .fetchOrderReturn(id)
        .then((res) => {
          if (res.data) {
            this.orderReturn = res.data.data;

            this.$store.commit("title", `Retoure #${this.orderReturn.id}`);

            document.title = `${this.$store.getters.title} | neXchance IT GmbH`;

            this.fetchOrderReturnAttachments();
            this.fetchOrder();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    fetchOrdersReturnArticles() {
      return this.postService
        .fetchOrdersReturnArticles({
          "filter[order_return_id]==": this.orderReturn.id,
          "order=": "BEP_ID",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderReturnArticles = res.data.data;

            for (let orderReturnArticlesIndex in this.orderReturnArticles) {
              let orderReturnArticle =
                this.orderReturnArticles[orderReturnArticlesIndex];

              let orderArticleSearch = this.orderArticles.filter(function (
                orderArticle
              ) {
                return orderArticle.ID == orderReturnArticle.BEP_ID;
              });

              if (orderArticleSearch.length) {
                this.orderReturnArticles[orderReturnArticlesIndex].BEP =
                  orderArticleSearch[0];
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderReturnAttachments() {
      return this.postService
        .fetchOrderReturnAttachments({
          "filter[order_return_id]==": this.orderReturn.id,
          "order=": "created_at",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderReturnAttachments = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrder() {
      return this.postService
        .fetchOrders({
          "filter[BELEGNR]==": this.orderReturn.order_number,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.order = res.data.data[0];

            this.fetchOrderArticles();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderArticles() {
      return this.postService
        .fetchOrderArticles({
          "filter[BELEGNR]==": this.orderReturn.order_number,
          "filter[PARENT_ID]=": null,
          "order=": "PO",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderArticles = res.data.data;

            this.fetchOrdersReturnArticles();
            this.fetchArticleImages();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchArticleImages() {
      this.orderArticles.forEach((article) => {
        return this.postService
          .fetchImages({
            "filter[TABELLE]==": "ART",
            "filter[TABELLE_ID]==": article.ART_ID,
            "order=": "BILDINDEX",
            "direction=": "ASC",
          })
          .then((res) => {
            if (res.data && res.data.status === "success") {
              this.orderArticlesImages[article.ART_ID] = res.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.$forceUpdate();
          });
      });
    },
    postOrderReturnLabel() {
      this.postOrderReturnLabelLoading = true;

      return this.postService
        .postOrderReturnLabel(this.orderReturn.id)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.fetchOrderReturnAttachments();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.postOrderReturnLabelLoading = false;
        });
    }
  },
  mounted() {
    this.fetchOrderReturn(this.$route.params.id);
  },
};
</script>
