import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.dialog,"max-width":"800"},on:{"input":_vm.close}},[_c(VCard,{attrs:{"outlined":"","loading":_vm.loading}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Komponenten")]),_c(VDivider),_c(VList,{attrs:{"two-line":""}},_vm._l((_vm.articles),function(article){return _c(VListItem,{key:article.ID,attrs:{"link":""}},[_c(VListItemAvatar,{staticClass:"secondary white--text"},[_vm._v(_vm._s(_vm._f("formatInt")(article.MENGE)))]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(article.BEZEICHNUNG))]),_c(VListItemSubtitle,[_vm._v("Artikelnummer: "+_vm._s(article.ARTIKELNR))])],1)],1)}),1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v("Schließen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }