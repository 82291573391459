import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.alerts.data,"items-per-page":50,"loading":_vm.loading,"loading-text":"Lade...","sort-by":"ID","sort-desc":"","no-data-text":"Keine Daten vorhanden","footer-props":{
          'items-per-page-options': [50, 100],
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }