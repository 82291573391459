import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY');
    }
});

Vue.filter('formatDateString', function (value) {
    if (value) {
        moment.locale('de');

        return moment(String(value)).format('Do MMM YYYY');
    }
});

Vue.filter('formatNumber', function (value) {
    if (isNaN(value)) {
        return value;
    }
    else {
        return parseFloat(value).toFixed(2).toString().replace('.', ',');
    }
});

Vue.filter('formatNumberPrettify', function (value) {
    return value.toString().replace('.', ',');
});

Vue.filter('formatInt', function (value) {
    if (isNaN(value)) {
        return value;
    }
    else {
        return parseInt(value);
    }
});

Vue.filter('extractFileName', function (filename) {
    if (filename) {
        return filename.split('.').slice(0, -1).join('.')
    }

    return filename;
});

Vue.filter('log', function (value) {
    if (value) {
        console.log(value);
    }

    return false;
});

export default new moment;