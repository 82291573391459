<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <StatsCard
            icon="mdi-package-variant-closed"
            color="#009688"
            headline="Offene Bestellungen"
            :text="`${
              Object.keys(orderList).includes('total') ? orderList.total : '-'
            }`"
            :to="Object.keys(orderList).includes('total') ? `/orders` : ''"
            :loading="!Object.keys(orderList).includes('total')"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="this.$store.getters.user.address.PGR_ID !== 3"
        >
          <StatsCard
            icon="mdi-hammer-wrench"
            color="#FF5722"
            headline="Letzte Serviceanträge"
            :text="`${
              Object.keys(orderWarrantyList).includes('total')
                ? orderWarrantyList.total
                : '-'
            }`"
            :to="
              Object.keys(orderWarrantyList).includes('total')
                ? `/warranty`
                : ''
            "
            :loading="!Object.keys(orderWarrantyList).includes('total')"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="this.$store.getters.user.address.PGR_ID !== 3"
        >
          <StatsCard
            icon="mdi-truck-delivery-outline"
            color="#3F51B5"
            headline="Letzte Retouren"
            :text="`${
              Object.keys(orderReturnList).includes('total')
                ? orderReturnList.total
                : '-'
            }`"
            :to="
              Object.keys(orderReturnList).includes('total') ? `/return` : ''
            "
            :loading="!Object.keys(orderReturnList).includes('total')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4" sm="6">
          <v-card outlined :loading="!Object.keys(orderList).includes('total')">
            <v-card-title class="headline">Offene Bestellungen</v-card-title>
            <v-divider></v-divider>

            <div v-if="orderList.total">
              <v-list
                two-line
                v-for="order in orderList.data"
                :key="order.ID"
                class="pt-0 pb-0"
              >
                <v-list-item link :to="{ path: `/orders/${order.ID}` }">
                  <v-list-item-content>
                    <v-list-item-title>#{{ order.BELEGNR }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="text-right" v-if="order.OHNEMWST === 'J'">
                      {{
                        order.BETRAGNETTO1 > 0
                          ? order.BETRAGNETTO1
                          : 0 | formatNumber
                      }}
                      {{ order.WAEHRUNG }}
                    </div>
                    <div class="text-right" v-else>
                      {{
                        order.BETRAGBRUTTO > 0
                          ? order.BETRAGBRUTTO
                          : 0 | formatNumber
                      }}
                      {{ order.WAEHRUNG }}
                    </div>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>
              </v-list>

              <v-btn block color="secondary" tile to="/orders"
                >Alle anzeigen</v-btn
              >
            </div>

            <v-card-text v-else>Derzeit keine offenen Bestellungen</v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="this.$store.getters.user.address.PGR_ID !== 3"
        >
          <v-card
            outlined
            :loading="!Object.keys(orderWarrantyList).includes('total')"
          >
            <v-card-title class="headline">Letzte Serviceanträge</v-card-title>
            <v-divider></v-divider>

            <div v-if="orderWarrantyList.total">
              <v-list
                two-line
                v-for="orderWarranty in orderWarrantyList.data"
                :key="orderWarranty.id"
                class="pt-0 pb-0"
              >
                <v-list-item
                  link
                  :to="{ path: `/warranty/${orderWarranty.id}` }"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >S{{ orderWarranty.id }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Auftragsnummer: #{{
                        orderWarranty.order_number
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
              </v-list>

              <v-btn
                block
                color="secondary"
                tile
                to="/warranty"
                class="white--text"
                >Alle anzeigen</v-btn
              >
            </div>

            <v-card-text v-else
              >Derzeit keine Serviceanträge vorhanden</v-card-text
            >
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="this.$store.getters.user.address.PGR_ID !== 3"
        >
          <v-card
            outlined
            :loading="!Object.keys(orderReturnList).includes('total')"
          >
            <v-card-title class="headline">Letzte Retouren</v-card-title>
            <v-divider></v-divider>

            <div v-if="orderReturnList.total">
              <v-list
                two-line
                v-for="orderReturn in orderReturnList.data"
                :key="orderReturn.id"
                class="pt-0 pb-0"
              >
                <v-list-item link :to="{ path: `/return/${orderReturn.id}` }">
                  <v-list-item-content>
                    <v-list-item-title>R{{ orderReturn.id }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Auftragsnummer: #{{
                        orderReturn.order_number
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
              </v-list>

              <v-btn
                block
                color="secondary"
                tile
                to="/return"
                class="white--text"
                >Alle anzeigen</v-btn
              >
            </div>

            <v-card-text v-else>Derzeit keine Retouren vorhanden</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";
import StatsCard from "../components/StatsCard";

export default {
  components: {
    StatsCard,
  },
  data() {
    this.$store.commit("title", "Dashboard");

    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.previousDate = false;

    return {
      orderList: false,
      orderWarrantyList: false,
      orderReturnList: false,
    };
  },
  methods: {
    fetchOpenOrders() {
      this.postService
        .fetchMissingReceiptOrders({
          "filter[BELEGART]=!": "0220",
          "receipt=": "04",
          "limit=": 5,
          "order=": "BELEGDATUM",
          "direction=": "DESC",
        })
        .then((res) => {
          this.orderList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrdersWarranty() {
      this.postService
        .fetchOrdersWarranty({
          "limit=": 5,
          "order=": "created_at",
          "direction=": "DESC",
        })
        .then((res) => {
          this.orderWarrantyList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrdersReturn() {
      this.postService
        .fetchOrdersReturn({
          "limit=": 5,
          "order=": "created_at",
          "direction=": "DESC",
        })
        .then((res) => {
          this.orderReturnList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.fetchOpenOrders();
    this.fetchOrdersWarranty();
    this.fetchOrdersReturn();
  },
};
</script>
