<template>
  <v-snackbar @input="close" :value="snackbar">
    {{ text }}
    <v-btn color="red" icon @click.stop="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "ArticleComponents",
  props: {
    snackbar: {
      type: Boolean,
      required: true,
      default: false
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit("update:snackbar", false);
    }
  }
};
</script>