<template>
  <div></div>
</template>

<script>
export default {
  data() {
    this.$store.commit("title", "Logout");

    return {};
  },
  mounted() {
    this.$store.commit("loading", true);

    localStorage.removeItem("address");

    this.$store.commit("userAddress", false);

    this.$router.push("login");

    this.$store.commit("loading", false);
  },
};
</script>