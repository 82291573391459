<template>
  <v-main>
    <v-container fluid>
      <v-card v-if="articlePrices" outlined>
        <v-card-title class="headline">{{ this.$store.getters.title }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-text-field class="mr-5" width="1" v-model="differenceModal" single-line
            hint="Übernahme von Preisen mit max. VK Differenz" label="VK Differenz">
          </v-text-field>

          <v-btn color="secondary" :disabled="btnGetAllDisabled" :loading="btnGetAllLoading"
            @click="patchArticlePriceAll">Alle Übernehmen</v-btn>
        </v-card-actions>

        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SKU</th>
                <th class="text-left">Name</th>
                <th class="text-left">Markt</th>
                <th class="text-left">VK</th>
                <th class="text-left">VK BuyBox</th>
                <th class="text-left">VK Diff.</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody v-for="item in articlePrices" :key="item.ID">
              <tr v-if="item.VK14BRUTTO > item.VK16BRUTTO">
                <td>{{ item.ARTIKELNR }}</td>
                <td>{{ item.ARTIKELTEXT }}</td>
                <td>DE</td>
                <td>{{ item.VK14BRUTTO.toFixed(2) | formatNumberPrettify }} EUR</td>
                <td>{{ item.VK16BRUTTO.toFixed(2) | formatNumberPrettify }} EUR</td>
                <td>{{ (item.VK14BRUTTO - item.VK16BRUTTO).toFixed(2) | formatNumberPrettify }} EUR</td>
                <td class="text-right">
                  <v-btn depressed color="secondary" :disabled="item.disabled_de" :loading="item.loading_de"
                    @click="patchArticlePrice(item, 'de')">Zurücksetzen</v-btn>
                </td>
              </tr>
              <tr v-if="item.VK15BRUTTO > item.VK17BRUTTO">
                <td>{{ item.ARTIKELNR }}</td>
                <td>{{ item.ARTIKELTEXT }}</td>
                <td>AT</td>
                <td>{{ item.VK15BRUTTO.toFixed(2) | formatNumberPrettify }} EUR</td>
                <td>{{ item.VK17BRUTTO.toFixed(2) | formatNumberPrettify }} EUR</td>
                <td>{{ (item.VK15BRUTTO - item.VK17BRUTTO).toFixed(2) | formatNumberPrettify }} EUR</td>
                <td class="text-right">
                  <v-btn depressed color="secondary" :disabled="item.disabled_at" :loading="item.loading_at"
                    @click="patchArticlePrice(item, 'at')">Zurücksetzen</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";

export default {
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.previousDate = false;

    return {
      differenceModal: 5,
      articlePrices: false,
      btnGetAllDisabled: false,
      btnGetAllLoading: false,
    };
  },
  methods: {
    fetchArticlePricesRefurbedReset() {
      this.$store.commit("loading", true);

      this.postService
        .fetchArticlePricesRefurbedReset()
        .then((res) => {
          if (res.data) {
            this.articlePrices = res.data.data.map((element) => ({
              ...element,
              disabled_de: false,
              loading_de: false,
              disabled_at: false,
              loading_at: false,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    patchArticlePrice(item, market_code) {
      let params = {};

      if (market_code == "de") {
        params = {
          VK16: item.VK14,
          VK16BRUTTO: item.VK14BRUTTO,
        };

        item.loading_de = true;

        this.postService.UpdateMarketOfferByData(market_code, item.ARTIKELNR, 'EUR', item.VK14BRUTTO);
      } else if (market_code == "at") {
        params = {
          VK17: item.VK15,
          VK17BRUTTO: item.VK15BRUTTO,
        };

        item.loading_at = true;

        this.postService.UpdateMarketOfferByData(market_code, item.ARTIKELNR, 'EUR', item.VK15BRUTTO);
      }

      this.postService
        .patchArticlePrice(item.ARTIKELNR, params)
        .then((res) => {
          if (res.data) {
            if (market_code == "de") {
              item.disabled_de = true;
            }
            else if (market_code == "at") {
              item.disabled_at = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (market_code == "de") {
            item.loading_de = false;
          }
          else if (market_code == "at") {
            item.loading_at = false;
          }
        });
    },
    patchArticlePriceAll() {
      if (!isNaN(this.differenceModal)) {
        this.btnGetAllLoading = true;

        this.marketOffers.market_offers.forEach((item) => {
          if (
            item.loading === false &&
            item.disabled === false &&
            item.market_price.instance_buybox_info.suggested_offer_price_diff <=
            this.differenceModal
          ) {
            this.patchArticlePrice(item);
          }
        });

        this.btnGetAllLoading = false;
      }
    },
  },
  mounted() {
    this.$store.commit("title", this.$route.meta.title);

    this.fetchArticlePricesRefurbedReset();
  },
};
</script>