<template>
  <div>
    <v-app-bar app clipped-left :color="$vuetify.theme.themes.light.navbar">
      <v-app-bar-nav-icon class="white--text" @click.stop="drawer = !drawer" />

      <span class="title ml-3 mr-5 d-none d-md-flex white--text">
        neXchance
        <span class="font-weight-light pl-1 white--text">Kundenportal</span>
      </span>

      <Search />

      <v-menu offset-y v-if="
        $store.getters.user.address !== false &&
        this.$store.getters.user.address.ID === 100001
      ">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon color="#FAFAFA">mdi-alert-decagram</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/alerts/replication/orders">
            <v-list-item-action>
              <v-icon color="#009688">mdi-package-variant-closed</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Bestellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/alerts/replication/articles">
            <v-list-item-action>
              <v-icon>mdi-laptop</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Artikel</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/alerts/replication/addresses">
            <v-list-item-action>
              <v-icon>mdi-account-alert-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Adressen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }" v-if="$store.getters.user.address !== false">
          <v-btn icon to="/logout" v-bind="attrs" v-on="on">
            <v-icon color="#FAFAFA">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Abmelden</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped :color="$vuetify.theme.themes.light.background">
      <v-list nav dense>
        <v-list-item to="/" v-if="$store.getters.user.address !== false">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="#009688" to="/orders" v-if="$store.getters.user.address !== false">
          <v-list-item-action>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Bestellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group :value="false" color="#FF5722" prepend-icon="mdi-hammer-wrench" no-action v-if="
          $store.getters.user.address !== false &&
          this.$store.getters.user.address.PGR_ID !== 3
        ">
          <template v-slot:activator>
            <v-list-item-title>Serviceanträge</v-list-item-title>
          </template>

          <v-list-item to="/warranty" exact>
            <v-list-item-content>
              <v-list-item-title>Übersicht</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/warranty/add" exact>
            <v-list-item-content>
              <v-list-item-title>Erstellen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" color="#3F51B5" prepend-icon="mdi-truck-delivery-outline" no-action v-if="
          $store.getters.user.address !== false &&
          this.$store.getters.user.address.PGR_ID !== 3
        ">
          <template v-slot:activator>
            <v-list-item-title>Retouren</v-list-item-title>
          </template>

          <v-list-item to="/return" exact>
            <v-list-item-content>
              <v-list-item-title>Übersicht</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/return/add" exact>
            <v-list-item-content>
              <v-list-item-title>Erstellen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" no-action v-if="
          $store.getters.user.address !== false &&
          this.$store.getters.user.address.ID === 100001
        ">
          <template v-slot:activator>
            <v-list-item-title>Refurbed</v-list-item-title>
          </template>

          <v-list-item to="/refurbed/buy-box" exact>
            <v-list-item-content>
              <v-list-item-title>BuyBox</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/refurbed/buy-box-reset" exact>
            <v-list-item-content>
              <v-list-item-title>BuyBox zurücksetzen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/refurbed/offer/import" exact>
            <v-list-item-content>
              <v-list-item-title>Angebote importieren</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/refurbed/offer/import-new" exact>
            <v-list-item-content>
              <v-list-item-title>Angebote importieren NEU</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item to="/login" v-if="$store.getters.user.address === false">
          <v-list-item-action>
            <v-icon>mdi-login</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append v-if="$store.getters.user.address !== false">
        <v-divider />

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-img width="20" src="@/assets/logo.png" alt="neXchance IT GmbH" contain />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $store.getters.version
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbarModel" :multi-line="true" timeout="10000" right>
      <div v-for="(result, index) in snackbarText" :key="index">
        <div v-if="result">
          <v-icon color="primary">mdi-check</v-icon>

          {{ index }}
        </div>
        <div v-else>
          <v-icon color="error">mdi-close</v-icon>

          {{ index }}
        </div>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="error" icon v-bind="attrs" @click="snackbarModel = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import PostService from "../PostService";
import Search from "./Search";

export default {
  components: {
    Search,
  },
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    return {
      drawer: null,

      snackbarModel: false,
      snackbarText: "",
    };
  },
};
</script>
