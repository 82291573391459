<template>
  <v-main>
    <v-container>
      <v-card outlined>
        <v-data-table
          :headers="headers"
          :items="alerts.data"
          :items-per-page="50"
          :loading="loading"
          loading-text="Lade..."
          sort-by="ID"
          sort-desc
          no-data-text="Keine Daten vorhanden"
          :footer-props="{
            'items-per-page-options': [50, 100],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }"
        ></v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";

export default {
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    return {
      alerts: [],

      loading: true,

      headers: [
        { text: "ID", value: "ID" },
        { text: "BETREFF", value: "BETREFF" },
        { text: "ANREDE", value: "ANREDE" },
        { text: "NAMEZEILE1", value: "NAMEZEILE1" },
        { text: "STRASSE", value: "STRASSE" },
        { text: "PLZ", value: "PLZ" },
        { text: "ORT", value: "ORT" },
        { text: "EMAIL", value: "EMAIL" },
        { text: "BELEGDATUM", value: "BELEGDATUM" },
        { text: "Fehler", value: "WEB_IMPHINWEIS" },
      ],
    };
  },
  methods: {
    fetchAlertReplication() {
      this.loading = true;

      this.postService
        .fetchAlertReplication({
          "limit=": 500,
          "order=": "ID",
          "filter[WEB_IMPSTATUS]==": null,
          "filter[WEB_IMPHINWEIS]=": "not null",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.alerts = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAlertReplication();
  },
};
</script>