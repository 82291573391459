import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Retouren")]),_c(VDivider),_vm._l((_vm.OrderReturnList.data),function(returnOrder){return _c(VList,{key:returnOrder.id,staticClass:"pt-0 pb-0",attrs:{"two-line":""}},[_c(VListItem,{attrs:{"link":"","to":{ path: ("/return/" + (returnOrder.id)) }}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("R"+_vm._s(returnOrder.id))]),_c(VListItemSubtitle,[_vm._v("Auftragsnummer: #"+_vm._s(returnOrder.order_number))])],1)],1),_c(VDivider)],1)})],2),_c('div',{staticClass:"text-center mt-3"},[_c(VPagination,{attrs:{"length":_vm.pagination.total,"total-visible":20,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},on:{"input":_vm.changePage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }