<template>
  <v-main>
    <v-container>
      <v-card outlined>
        <v-card-title class="headline">{{ this.$store.getters.title }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-file-input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
" label="Dateiimport" type="file" @change="onFileChange"></v-file-input>

          <v-divider></v-divider>

          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form @submit.prevent="addOfferForm">
              <v-row>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="SKU" rules="required|integer">
                    <v-text-field class="mr-5" v-model="skuModel" :error-messages="errors" single-line
                      hint="SKU aus VARIO" label="SKU" required>
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col>
                  <validation-provider v-slot="{ errors }" name="Instance ID" rules="required|integer">
                    <v-text-field class="mr-5" v-model="instanceIDModel" :error-messages="errors" single-line
                      hint="Instance ID von Refurbed" label="Instance ID" required>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn color="secondary" :disabled="invalid || addOfferFormButtonDisabled"
                    :loading="addOfferFormButtonLoading" type="submit">Hinzufügen</v-btn>
                </v-col>
                <v-col>
                  <v-checkbox v-model="checkboxVarioModel" label="Artikeldaten aus VARIO laden">
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="offers">
      <v-card outlined>
        <v-card-title class="headline">Angebote {{ offers.length ? `(${offers.length})` : null }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">SKU</th>
                  <th class="text-left">Instance ID</th>
                  <th class="text-left">VARIO Bezeichnung</th>
                  <th class="text-left">Refurbed Bezeichnung</th>
                  <th class="text-left">Offer ID</th>
                  <th class="text-left">Fehler</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in offers" :key="item.sku + item.instance_id"
                  :class="item.errors.length ? 'red' : null">
                  <td>{{ item.sku ? item.sku : '-' }}</td>
                  <td>{{ item.instance_id ? item.instance_id : '-' }}</td>
                  <td>{{ item.name_vario ? item.name_vario : '-' }}</td>
                  <td>{{ item.name_refurbed ? item.name_refurbed : '-' }}</td>
                  <td>
                    <v-btn :href="`https://merchant.refurbed.com/offers/details/${item.offer_id}`"
                      :disabled="!item.offer_id" target="_blank" text>
                      {{ item.offer_id ? item.offer_id : "-" }}
                    </v-btn>
                  </td>
                  <td>
                    <ul v-if="item.errors.length">
                      <li v-for="error in item.errors" :key="item.sku + item.instance_id + error.id">
                        {{ error.description }}
                      </li>
                    </ul>

                    {{ !item.errors.length ? `-` : null }}
                  </td>
                  <td>
                    <v-btn color="secondary" :disabled="
                      item.errors.length > 0 || item.offer_id !== null
                    " :loading="item.loading" @click="createRefurbedOffer(index)">Einstellen</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PostService from "../PostService";
import { required, integer } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import readXlsxFile from "read-excel-file";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Pflichtfeld",
});

extend("integer", {
  ...integer,
  message: "Ungültiger Zahlenwert",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.errors = {
      0: {
        id: 0,
        description: "Ungültige SKU",
      },
      1: {
        id: 1,
        description: "Ungültige Instance ID",
      },
      10: {
        id: 10,
        description: "Fehler beim Laden von Artikeldaten",
      },
      20: {
        id: 20,
        description: "Angebot bereits vorhanden",
      },
    };

    return {
      offers: [],

      checkboxVarioModel: true,
      skuModel: null,
      instanceIDModel: null,
      addOfferFormButtonDisabled: false,
      addOfferFormButtonLoading: false,
    };
  },
  methods: {
    onFileChange(file) {
      readXlsxFile(file).then((rows) => {
        let skuIndex = null;
        let instanceIdIndex = null;

        rows.forEach((row, rowIndex) => {
          row.forEach((col, colIndex) => {
            if (rowIndex === 0) {
              if (col.match(/sku/i) || col.match(/artikeln/i)) {
                skuIndex = colIndex;
              } else if (col.match(/ref/i) || col.match(/instan/i)) {
                instanceIdIndex = colIndex;
              }
            }
          });
        });

        if (skuIndex !== null && instanceIdIndex !== null) {
          rows.forEach((row, rowIndex) => {
            let sku = null;
            let instance_id = null;

            row.forEach((col, colIndex) => {
              if (rowIndex > 0) {
                if (colIndex === skuIndex) {
                  sku = col;
                } else if (colIndex === instanceIdIndex) {
                  instance_id = col;
                }
              }
            });

            if (sku && instance_id) {
              this.addOffer(sku, instance_id);
            }
          });
        }
      });
    },
    addOffer(sku, instance_id) {
      let offer = [];

      offer["sku"] = sku;
      offer["instance_id"] = instance_id;
      offer["ean"] = null;
      offer["name_vario"] = null;
      offer["name_refurbed"] = null;
      offer["offer_id"] = null;
      offer["shipping_profile_id"] = 110;
      offer["grading"] = "A";
      offer["warranty"] = "M12";
      offer["taxation"] = "GROSS";
      offer["reference_price"] = 0;
      offer["reference_min_price"] = 0;
      offer["reference_currency_code"] = "EUR";
      offer["loading"] = false;
      offer["errors"] = [];

      if (isNaN(sku)) {
        offer["errors"].push(this.errors[0]);
      }

      if (isNaN(instance_id)) {
        offer["errors"].push(this.errors[1]);
      }

      if (!offer.errors.length) {
        let Promises = [];

        if (this.checkboxVarioModel) {
          Promises.push(this.fetchArticles(offer))
        }

        Promises.push(this.fetchRefurbedInstance(offer))
        Promises.push(this.fetchRefurbedOffer(offer))

        Promise.all(Promises)
          .then((res) => {
            res.forEach((values) => {
              if (typeof values !== "undefined") {
                for (let [key, value] of Object.entries(values)) {
                  offer[key] = value;
                }
              } else {
                offer.errors.push(this.errors[10]);
              }
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            if (offer["offer_id"]) {
              offer.errors.push(this.errors[20]);
            }

            this.offers.push(offer);
          });
      } else {
        this.offers.push(offer);
      }
    },
    addOfferForm() {
      this.addOfferFormButtonDisabled = true;

      this.addOffer(this.skuModel, this.instanceIDModel);
    },
    fetchArticles(offer) {
      return this.postService
        .fetchArticles({
          "filter[ARTIKELNR]==": offer.sku,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (res.data.total === 1) {
              return {
                name_vario: res.data.data[0].ARTIKELTEXT,
                ean: res.data.data[0].EANNR,
                reference_price: res.data.data[0].VKBRUTTO,
                reference_min_price: res.data.data[0].VKBRUTTO,
              };
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchRefurbedInstance(offer) {
      return this.postService
        .fetchRefurbedInstance(offer.instance_id)
        .then((res) => {
          if (res.data) {
            return { name_refurbed: res.data.instance.name };
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchRefurbedOffer(offer) {
      return this.postService
        .fetchRefurbedOffer(offer.sku)
        .then((res) => {
          if (res.data) {
            return {
              offer_id: res.data.offer.id,
            };
          }
        })
        .catch(() => {
          return {
            offer_id: null,
          };
        });
    },
    createRefurbedOffer(index) {
      let offer = this.offers[index];

      offer["loading"] = true;

      this.$set(this.offers, index, offer);

      return this.postService
        .createRefurbedOffer(offer)
        .then((res) => {
          if (res.data) {
            offer["offer_id"] = res.data.offer.id;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          offer["loading"] = false;

          this.$set(this.offers, index, offer);
        });
    },
  },
  mounted() {
    this.$store.commit("title", this.$route.meta.title);
  },
  watch: {
    skuModel: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.addOfferFormButtonDisabled !== false) {
          this.addOfferFormButtonDisabled = false;
        }
      }
    },
    instanceIDModel: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.addOfferFormButtonDisabled !== false) {
          this.addOfferFormButtonDisabled = false;
        }
      }
    },
  },
};
</script>