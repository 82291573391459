<template>
  <v-app
    id="inspire"
    :style="{ background: $vuetify.theme.themes.light.background }"
  >
    <Navbar v-if="this.$store.getters.user.address !== false" />

    <ProgressBar />

    <router-view />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import ProgressBar from "./components/ProgressBar";
import PackageJSON from "../package.json";

export default {
  components: {
    Navbar,
    ProgressBar,
  },
  mounted() {
    if (
      this.$route.meta.auth === true &&
      this.$store.getters.user.address === false
    ) {
      this.$router.push("logout");
    }

    this.$store.commit("version", PackageJSON.version);
  },
  watch: {
    $route: {
      handler: (route) => {
        document.title = `${route.meta.title} | neXchance IT GmbH`;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>