<template>
  <v-main>
    <ArticleComponents v-if="this.orderArticles" :dialog.sync="articleComponentsDialog" :orderID.sync="order.ID"
      :articleID.sync="articleComponentsArticlePOID" />
    <ArticleSpecifications :dialog.sync="articleSpecificationsDialog"
      :articleID.sync="articleSpecificationsArticleID" />
    <PDFViewer :dialog.sync="PDFViewerDialog" :filename.sync="PDFViewerFilename" :url.sync="PDFViewerURL" />

    <v-container>
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <StatsCard icon="mdi-pound" color="#FF5722" headline="Serviceantragsnummer"
            :text="`S${this.orderWarranty.id}`" :loading="!Object.keys(orderWarranty).includes('id')" />
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <StatsCard icon="mdi-package-variant-closed" color="#009688" headline="Auftragsnummer"
            :text="`${this.orderWarranty.order_number}`"
            :to="Object.keys(order).includes('ID') ? `/orders/${order.ID}` : ''"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>
      </v-row>

      <v-row v-if="
        Object.keys(orderWarranty).includes('description') &&
        orderWarranty.description
      ">
        <v-col>
          <v-card outlined height="100%" :loading="!Object.keys(orderWarranty).includes('description')">
            <v-card-title class="headline">Kommentar</v-card-title>
            <v-divider></v-divider>

            <v-card-text v-if="Object.keys(orderWarranty).includes('description')" style="white-space: pre-line">{{
              orderWarranty.description
            }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined :loading="!Object.keys(orderWarrantyAttachments).includes('total')">
            <v-card-title class="headline">Anhänge</v-card-title>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list two-line>
                  <v-list-item @click.stop="
                    (PDFViewerDialog = true),
                    (PDFViewerFilename = `Serviceantragsschein`),
                    (PDFViewerURL = `https://apiv2.nexchance.de/pdf/warranty/${orderWarranty.id}/view`)
                  ">
                    <v-list-item-avatar>
                      <v-icon color="red">mdi-file-document</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Serviceantragsschein</v-list-item-title>
                      <v-list-item-subtitle>Drucken Sie Ihr Retourenschein aus und legen Sie ihn
                        dem Paket bei</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (PDFViewerDialog = true),
                            (PDFViewerFilename = `Serviceantragsschein`),
                            (PDFViewerURL = `https://apiv2.nexchance.de/pdf/warranty/${orderWarranty.id}/view`)
                          ">
                            <v-list-item-action>
                              <v-icon color="red">mdi-file-document</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Serviceantragsschein</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-list v-for="attachment in orderWarrantyAttachments.data" :key="attachment.id">
                  <v-list-item @click.stop="
                    (PDFViewerDialog = true),
                    (PDFViewerFilename = `${$options.filters.extractFileName(
                      attachment.name
                    )}`),
                    (PDFViewerURL = `https://apiv2.nexchance.de/system/order/warranty/attachments/${attachment.id}/view`)
                  ">
                    <v-list-item-avatar>
                      <v-icon v-if="attachment.parcel_url" :color="
                        attachment.parcel_received_at ? 'green' : 'orange'
                      ">mdi-truck</v-icon>
                      <v-icon v-else>mdi-file-document</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        attachment.name | extractFileName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        attachment.description
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (PDFViewerDialog = true),
                            (PDFViewerFilename = `${$options.filters.extractFileName(
                              attachment.name
                            )}`),
                            (PDFViewerURL = `https://apiv2.nexchance.de/system/order/warranty/attachments/${attachment.id}/view`)
                          ">
                            <v-list-item-action>
                              <v-icon color="red">mdi-file-document</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                attachment.name | extractFileName
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="attachment.parcel_url" :href="attachment.parcel_url" target="_blank">
                            <v-list-item-action>
                              <v-icon>mdi-truck</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Sendungsverfolgung</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn block color="secondary" link @click.stop="postOrderWarrantyLabel"
                :loading="postOrderWarrantyLabelLoading">
                <v-icon left> mdi-plus </v-icon>
                Retourenlabel erstellen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined :loading="!Object.keys(orderWarrantyArticles).includes('0')">
            <v-card-title class="headline">Artikel</v-card-title>
            <v-divider></v-divider>

            <v-list-item v-if="orderWarrantyArticles">
              <v-list-item-content>
                <v-list two-line>
                  <v-list-item link v-for="orderWarrantyArticle in orderWarrantyArticles"
                    :key="orderWarrantyArticle.id">
                    <v-list-item-avatar tile>
                      <v-avatar tile>
                        <v-icon v-if="
                          !orderArticlesImages[
                          orderWarrantyArticle.BEP.ART_ID
                          ] ||
                          !orderArticlesImages[
                          orderWarrantyArticle.BEP.ART_ID
                          ][0]
                        ">mdi-camera</v-icon>

                        <v-img v-else :src="`${config.hostname}/vario/images/${orderArticlesImages[
                        orderWarrantyArticle.BEP.ART_ID
                      ][0].ID
                        }/view`" :alt="
    orderArticlesImages[
      orderWarrantyArticle.BEP.ART_ID
    ][0].DATEINAME
  " contain />
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        orderWarrantyArticle.BEP.LANGTEXT
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Artikelnummer:
                        {{ orderWarrantyArticle.BEP.ARTIKELNR }} | Menge:
                        {{
                          orderWarrantyArticle.MENGE | formatInt
                        }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="orderWarrantyArticle.BEP.ARTIKELART == 5">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (articleSpecificationsDialog = true),
                            (articleSpecificationsArticleID =
                              orderWarrantyArticle.BEP.ART_ID)
                          ">
                            <v-list-item-action>
                              <v-icon>mdi-view-module</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Technische Daten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click.stop="
  (articleComponentsDialog = true),
  (articleComponentsArticlePOID =
    orderWarrantyArticle.BEP.ID)
                          ">
                            <v-list-item-action>
                              <v-icon>mdi-screwdriver</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Komponenten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import PostService from "../PostService";
import ArticleComponents from "../components/Order/Article/Components";
import ArticleSpecifications from "../components/Article/Specifications";
import StatsCard from "../components/StatsCard";
import PDFViewer from "../components/PDFViewer";

export default {
  components: {
    ArticleComponents,
    ArticleSpecifications,
    StatsCard,
    PDFViewer,
  },
  data() {
    this.config = config;

    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );
    return {
      orderWarranty: new Object(),
      orderWarrantyArticles: new Object(),
      orderWarrantyAttachments: new Object(),

      order: new Object(),
      orderArticles: new Object(),
      orderArticlesImages: new Array(),

      articleComponentsDialog: false,
      articleComponentsArticlePOID: false,

      articleSpecificationsDialog: false,
      articleSpecificationsArticleID: false,

      PDFViewerDialog: false,
      PDFViewerFilename: false,
      PDFViewerURL: false,

      postOrderWarrantyLabelLoading: false
    };
  },
  methods: {
    fetchOrderWarranty(id) {
      this.$store.commit("loading", true);

      return this.postService
        .fetchOrderWarranty(id)
        .then((res) => {
          if (res.data) {
            this.orderWarranty = res.data.data;

            this.$store.commit(
              "title",
              `Serviceantrag #${this.orderWarranty.id}`
            );

            document.title = `${this.$store.getters.title} | neXchance IT GmbH`;

            this.fetchOrderWarrantyAttachments();
            this.fetchOrder();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    fetchOrdersWarrantyArticles() {
      return this.postService
        .fetchOrdersWarrantyArticles({
          "filter[order_warranty_id]==": this.orderWarranty.id,
          "order=": "BEP_ID",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderWarrantyArticles = res.data.data;

            for (let orderWarrantyArticlesIndex in this.orderWarrantyArticles) {
              let orderWarrantyArticle =
                this.orderWarrantyArticles[orderWarrantyArticlesIndex];

              let orderArticleSearch = this.orderArticles.filter(function (
                orderArticle
              ) {
                return orderArticle.ID == orderWarrantyArticle.BEP_ID;
              });

              if (orderArticleSearch.length) {
                this.orderWarrantyArticles[orderWarrantyArticlesIndex].BEP =
                  orderArticleSearch[0];
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderWarrantyAttachments() {
      return this.postService
        .fetchOrderWarrantyAttachments({
          "filter[order_warranty_id]==": this.orderWarranty.id,
          "order=": "created_at",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderWarrantyAttachments = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrder() {
      return this.postService
        .fetchOrders({
          "filter[BELEGNR]==": this.orderWarranty.order_number,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.order = res.data.data[0];

            this.fetchOrderArticles();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderArticles() {
      return this.postService
        .fetchOrderArticles({
          "filter[BELEGNR]==": this.orderWarranty.order_number,
          "filter[PARENT_ID]=": null,
          "order=": "PO",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderArticles = res.data.data;

            this.fetchOrdersWarrantyArticles();
            this.fetchArticleImages();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchArticleImages() {
      this.orderArticles.forEach((article) => {
        return this.postService
          .fetchImages({
            "filter[TABELLE]==": "ART",
            "filter[TABELLE_ID]==": article.ART_ID,
            "order=": "BILDINDEX",
            "direction=": "ASC",
          })
          .then((res) => {
            if (res.data && res.data.status === "success") {
              this.orderArticlesImages[article.ART_ID] = res.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.$forceUpdate();
          });
      });
    },
    postOrderWarrantyLabel() {
      this.postOrderWarrantyLabelLoading = true;

      return this.postService
        .postOrderWarrantyLabel(this.orderWarranty.id)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.fetchOrderWarrantyAttachments();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.postOrderWarrantyLabelLoading = false;
        });
    }
  },
  mounted() {
    this.fetchOrderWarranty(this.$route.params.id);
  },
};
</script>