<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col md="3">
          <v-select
            v-model="orderTypeModel"
            :items="orderTypeItems"
            label="Bestelltyp"
          ></v-select>
        </v-col>

        <v-col md="3">
          <v-select
            v-model="orderByModel"
            :items="orderByItems"
            label="Sortieren nach"
          ></v-select>
        </v-col>

        <v-col md="3">
          <v-select
            v-model="orderDirectionModel"
            :items="orderDirectionItems"
            label="Sortierreihenfolge"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card v-if="orderList" outlined>
            <v-card-title class="headline">{{
              this.$store.getters.title
            }}</v-card-title>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>

      <!--
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Auftragsnr.</th>
                <th class="text-left">Bestell-Nr.</th>
                <th class="text-left">Belegdatum</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :to="{ path: `/orders/${order.ID}` }"
                v-for="order in orderList.data"
                :key="order.ID"
              >
                <td>#{{ order.BELEGNR }}</td>
                <td>{{ order.BETREFF }}</td>
                <td>{{ order.BELEGDATUM | formatDateString }}</td>
                <td class="text-right" v-if="order.OHNEMWST === 'J'">
                  {{
                    order.BETRAGNETTO1 > 0
                      ? order.BETRAGNETTO1
                      : 0 | formatNumber
                  }}
                  {{ order.WAEHRUNG }}
                </td>
                <td class="text-right" v-else>
                  {{
                    order.BETRAGBRUTTO > 0
                      ? order.BETRAGBRUTTO
                      : 0 | formatNumber
                  }}
                  {{ order.WAEHRUNG }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        -->

      <v-row>
        <v-col cols="12" v-for="order in orderList.data" :key="order.ID">
          <v-card outlined :loading="!order.BEP">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ order.BELEGDATUM | formatDateString }}

                  {{ order.BETREFF ? " | " + order.BETREFF : "" }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  #{{ order.BELEGNR }}
                </v-list-item-title>

                <v-list-item-subtitle v-if="order.BEP">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Artikelnr</th>
                          <th class="text-left">Bezeichnung</th>
                          <th class="text-left">Menge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="BEP in order.BEP" :key="BEP.ID">
                          <td>{{ BEP.ARTIKELNR }}</td>
                          <td>{{ BEP.LANGTEXT }}</td>
                          <td>
                            {{ parseInt(BEP.MENGE) }} {{ BEP.ME ? BEP.ME : "" }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="secondary"
                link
                :to="{ path: `/orders/${order.ID}` }"
              >
                Aufrufen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="text-center mt-3">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.total"
          :total-visible="20"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import PostService from "../PostService";

export default {
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.previousDate = false;
    this.config = config;

    return {
      // Filter
      filterDrawer: false,

      orderList: false,

      // Bestelltyp
      orderTypeModel: null,
      orderTypeItems: [
        { text: "Alle", value: "orders" },
        { text: "Offen", value: "orders_opened" },
        { text: "Abgeschlossen", value: "orders_closed" },
        { text: "Storniert", value: "orders_canceled" },
      ],

      // Sortieren nach
      orderByModel: "BELEGNR",
      orderByItems: [
        { text: "Auftragsnummer", value: "BELEGNR" },
        { text: "Datum", value: "BELEGDATUM" },
      ],

      // Sortierreihenfolge
      orderDirectionModel: "DESC",
      orderDirectionItems: [
        { text: "Absteigend", value: "DESC" },
        { text: "Aufsteigend", value: "ASC" },
      ],

      // Paginierung
      pagination: {
        page: 1,
        offset: 0,
        limit: 20,
        total: 0,
      },
    };
  },
  methods: {
    fetchOrders() {
      this.$store.commit("loading", true);

      this.postService
        .fetchOrders({
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "limit=": this.pagination.limit,
          "offset=": this.pagination.offset,
          "order=": this.orderByModel,
          "direction=": this.orderDirectionModel,
        })
        .then((res) => {
          if (res.data) {
            this.mapOrderList(res.data);

            this.pagination.total = Math.floor(
              res.data.total / this.pagination.limit
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    fetchOpenOrders() {
      this.$store.commit("loading", true);

      this.postService
        .fetchMissingReceiptOrders({
          "filter[BELEGART]=!": "0220",
          "receipt=": "04",
          "limit=": this.pagination.limit,
          "offset=": this.pagination.offset,
          "order=": this.orderByModel,
          "direction=": this.orderDirectionModel,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.mapOrderList(res.data);

            this.pagination.total = Math.floor(
              res.data.total / this.pagination.limit
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    fetchClosedOrders() {
      this.$store.commit("loading", true);

      this.postService
        .fetchPresentReceiptOrders({
          "receipt=": "04",
          "filter[BELEGART]=!": "0220",
          "limit=": this.pagination.limit,
          "offset=": this.pagination.offset,
          "order=": this.orderByModel,
          "direction=": this.orderDirectionModel,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.mapOrderList(res.data);

            this.pagination.total = Math.floor(
              res.data.total / this.pagination.limit
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    fetchCanceledOrders() {
      this.$store.commit("loading", true);

      this.postService
        .fetchOrders({
          "filter[BELEGSTATUS]==": 6,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "limit=": this.pagination.limit,
          "offset=": this.pagination.offset,
          "order=": this.orderByModel,
          "direction=": this.orderDirectionModel,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.mapOrderList(res.data);

            this.pagination.total = Math.floor(
              res.data.total / this.pagination.limit
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.commit("loading", false);
        });
    },
    fetchOrderArticles(order) {
      this.postService
        .fetchOrderArticles({
          "filter[BEK_ID]==": order.ID,
          "filter[PARENT_ID]=": null,
          "order=": "PO",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            order.BEP = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changePage(page) {
      this.pagination.page = page;
      this.pagination.offset =
        this.pagination.limit * (this.pagination.page - 1);

      this.refreshOrders();
    },
    refreshOrders() {
      this.previousDate = null;

      if (this.orderTypeModel === "orders") {
        this.$store.commit("title", "Alle Bestellungen");

        this.fetchOrders();
      } else if (this.orderTypeModel === "orders_opened") {
        this.$store.commit("title", "Offene Bestellungen");

        this.fetchOpenOrders();
      } else if (this.orderTypeModel === "orders_closed") {
        this.$store.commit("title", "Abgeschlossene Bestellungen");

        this.fetchClosedOrders();
      } else if (this.orderTypeModel === "orders_canceled") {
        this.$store.commit("title", "Stornierte Bestellungen");

        this.fetchCanceledOrders();
      }
    },
    mapOrderList(orderList) {
      orderList.data = orderList.data.map((element) => ({
        ...element,
        BEP: false,
      }));

      this.orderList = orderList;

      this.orderList.data.forEach((order) => {
        this.fetchOrderArticles(order);
      });
    },
    showDate(date) {
      if (this.previousDate !== date) {
        this.previousDate = date;

        return true;
      }

      return false;
    },
  },
  mounted() {
    this.$store.commit("title", this.$route.meta.title);

    this.orderTypeModel = "orders";
  },
  watch: {
    orderTypeModel(newVal, oldVal) {
      if (newVal !== oldVal && typeof oldVal !== "undefined") {
        this.refreshOrders();
      }
    },
    orderByModel(newVal, oldVal) {
      if (newVal !== oldVal && typeof oldVal !== "undefined") {
        this.refreshOrders();
      }
    },
    orderDirectionModel(newVal, oldVal) {
      if (newVal !== oldVal && typeof oldVal !== "undefined") {
        this.refreshOrders();
      }
    },
  },
};
</script>