<template>
  <v-main>
    <ArticleComponents v-if="this.order" :dialog.sync="articleComponentsDialog" :orderID.sync="order.ID"
      :articleID.sync="articleComponentsArticlePOID" />
    <ArticleSpecifications :dialog.sync="articleSpecificationsDialog"
      :articleID.sync="articleSpecificationsArticleID" />
    <PDFViewer :dialog.sync="PDFViewerDialog" :filename.sync="PDFViewerFilename" :url.sync="PDFViewerURL" />

    <v-container>
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <StatsCard icon="mdi-pound" color="#009688" headline="Auftragsnummer" :text="`#${order.BELEGNR}`"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>

        <v-col cols="12" md="4" sm="6" v-if="Object.keys(order).includes('BESTELLT_AM') && order.BESTELLT_AM">
          <StatsCard icon="mdi-calendar" color="#009688" headline="Bestelldatum"
            :text="`${this.$options.filters.formatDate(order.BESTELLT_AM)}`"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>
        <v-col cols="12" md="4" sm="6" v-if="order.BETREFF">
          <StatsCard icon="mdi-basket" color="#009688" headline="Bestellnummer" :text="order.BETREFF"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>
        <v-col cols="12" md="4" sm="6" v-if="order.S01">
          <StatsCard icon="mdi-truck-check" color="#009688" headline="Zustelldatum" :text="`${order.S01} Uhr`"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>
        <v-col cols="12" md="4" sm="6" v-if="order.S03">
          <StatsCard icon="mdi-hammer-wrench" color="#FF5722" headline="Gewährleistung" :text="`${order.S03} Monate`"
            :loading="!Object.keys(order).includes('ID')" />
        </v-col>
        <v-col cols="12" md="4" sm="6" v-if="order.S02">
          <StatsCard icon="mdi-truck-delivery-outline" color="#3F51B5" headline="Rückgabefrist"
            :text="`${order.S02} Tage`" :loading="!Object.keys(order).includes('ID')" />
        </v-col>
        <!--
        <v-col cols="12" sm="6" md="4" v-if="order.BETREFF">
          <StatsCard icon="mdi-basket" color="#424242" headline="Status" :text="order.BETREFF" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <StatsCard
            icon="mdi-credit-card-outline"
            color="secondary"
            headline="Zahlungsart"
            :text="order.payment.name.full"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <StatsCard
            icon="mdi-truck"
            color="secondary"
            headline="Versandart"
            :text="order.shipping.name.full"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <StatsCard
            icon="mdi-basket"
            color="secondary"
            :headline="`Gesamtsumme (${order.vat.free ? 'exkl.' : 'inkl.'} 19% MwSt.)`"
            :text="order.vat.free ? `${this.$options.filters.formatNumber(order.price.net)} ${order.currency}` : `${this.$options.filters.formatNumber(order.price.gross)} ${order.currency}`"
          />
        </v-col>
        -->
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card outlined :loading="!Object.keys(OrderReturnList).length">
            <v-card-title class="headline">Retouren</v-card-title>
            <v-divider></v-divider>

            <v-list v-if="Object.keys(OrderReturnList).length && OrderReturnList.result > 0" class="pt-0 pb-0">
              <v-list-item v-for="OrderReturn in OrderReturnList.data" :key="OrderReturn.id" link
                :to="{ path: `/return/${OrderReturn.id}` }">
                <v-list-item-content>
                  <v-list-item-title>R{{ OrderReturn.id }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </v-list>

            <v-list-item v-else-if="Object.keys(OrderReturnList).length && OrderReturnList.result == 0">
              <v-list-item-content>
                <v-list-item-subtitle>Keine Retouren
                  vorhanden</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions v-if="isReturn">
              <v-btn v-if="isReturn" block color="secondary" link :to="{ path: `/return/add/${order.AUFTRAGSNR}` }">
                <v-icon left> mdi-plus </v-icon>
                Retoure anlegen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card outlined :loading="!Object.keys(OrderWarrantyList).length">
            <v-card-title class="headline">Serviceanträge</v-card-title>
            <v-divider></v-divider>

            <v-list v-if="Object.keys(OrderWarrantyList).length && OrderWarrantyList.result > 0" class="pt-0 pb-0">
              <v-list-item v-for="OrderWarranty in OrderWarrantyList.data" :key="OrderWarranty.id" link
                :to="{ path: `/warranty/${OrderWarranty.id}` }">
                <v-list-item-content>
                  <v-list-item-title>S{{ OrderWarranty.id }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </v-list>

            <v-list-item v-else-if="Object.keys(OrderWarrantyList).length && OrderWarrantyList.result == 0">
              <v-list-item-content>
                <v-list-item-subtitle>Keine Serviceanträge
                  vorhanden</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions v-if="isWarranty">
              <v-btn v-if="isWarranty" block color="secondary" link :to="{ path: `/warranty/add/${order.AUFTRAGSNR}` }">
                <v-icon left> mdi-plus </v-icon>
                Serviceantrag anlegen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card outlined :loading="!Object.keys(orderAddresses).includes('1')">
            <v-card-title class="headline">Rechnungsadresse</v-card-title>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content v-if="Object.keys(orderAddresses).includes('1')">
                <v-list-item-subtitle style="white-space: pre-line">{{
                  orderAddresses[1].AUSGABE
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card outlined :loading="!Object.keys(orderAddresses).includes('2')">
            <v-card-title class="headline">Lieferadresse</v-card-title>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content v-if="Object.keys(orderAddresses).includes('2')">
                <v-list-item-subtitle style="white-space: pre-line">{{
                  orderAddresses[2].AUSGABE
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="Object.keys(orderInvoice).length">
        <v-col>
          <v-card outlined :loading="!Object.keys(orderInvoice).length">
            <v-card-title class="headline">Anhänge</v-card-title>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list two-line>
                  <v-list-item @click.stop="
                    (PDFViewerDialog = true),
                    (PDFViewerFilename = `${orderInvoice.name}`),
                    (PDFViewerURL = `https://apiv2.nexchance.de/pdf/invoice/${order.ID}/view`)
                  ">
                    <v-list-item-avatar>
                      <v-icon color="red">mdi-file-document</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        orderInvoice.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="Object.keys(order).includes('BEMERKUNG') && order.BEMERKUNG">
        <v-col>
          <v-card>
            <v-card-title class="headline">Bemerkung</v-card-title>
            <v-divider></v-divider>
            <v-card-text>{{ order.BEMERKUNG }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="Object.keys(orderLabels).includes('0')">
        <v-col md="6" v-for="(label, index) in orderLabels" :key="label.ID">
          <v-card hover :href="label.TRACKINGPFAD" target="_blank" outlined>
            <v-list-item>
              <v-list-item-content>
                <div class="overline mb-4" v-if="label.TRACKINGPFAD.includes('dhl')">
                  DHL Sendungsverfolgung
                </div>
                <div class="overline mb-4" v-else-if="label.TRACKINGPFAD.includes('gls')">
                  GLS Sendungsverfolgung
                </div>
                <v-list-item-title class="headline mb-1">Paket {{ ++index }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" v-if="label.TRACKINGPFAD.includes('dhl')">
                <v-img src="@/assets/tracking/dhl.png" alt="DHL Logo" contain />
              </v-list-item-avatar>
              <v-list-item-avatar tile size="80" v-else-if="label.TRACKINGPFAD.includes('gls')">
                <v-img src="@/assets/tracking/gls.png" alt="GLS Logo" contain />
              </v-list-item-avatar>
            </v-list-item>

            <v-simple-table>
              <tbody>
                <tr v-if="label.PAKETNR">
                  <td class="text-left">Sendungsverfolgungsnummer</td>
                  <td class="text-left">{{ label.PAKETNR }}</td>
                </tr>
                <tr v-if="label.GEWICHT">
                  <td class="text-left">Gewicht</td>
                  <td class="text-left">
                    {{ label.GEWICHT | formatNumber }}kg
                  </td>
                </tr>
                <tr v-if="label.ANG_DT">
                  <td class="text-left">Datum</td>
                  <td class="text-left">{{ label.ANG_DT | formatDate }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card outlined :loading="!Object.keys(orderArticles).includes('0')">
            <v-card-title class="headline">Artikel</v-card-title>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list two-line>
                  <v-list-item link v-for="article in orderArticles" :key="article.ID">
                    <v-list-item-avatar tile>
                      <v-avatar tile>
                        <v-icon v-if="
                          !orderArticlesImages[article.ART_ID] ||
                          !orderArticlesImages[article.ART_ID][0]
                        ">mdi-camera</v-icon>

                        <v-img v-else :src="`${config.hostname}/vario/images/${orderArticlesImages[article.ART_ID][0].ID
                        }/view`" :alt="
    orderArticlesImages[article.ART_ID][0].DATEINAME
  " contain />
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        article.LANGTEXT
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Artikelnummer: {{ article.ARTIKELNR }} | Menge
                        {{ article.MENGE | formatInt }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="article.ARTIKELART == 5">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.stop="
                            (articleSpecificationsDialog = true),
                            (articleSpecificationsArticleID =
                              article.ART_ID)
                          ">
                            <v-list-item-action>
                              <v-icon>mdi-view-module</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Technische Daten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click.stop="
                            (articleComponentsDialog = true),
                            (articleComponentsArticlePOID = article.ID)
                          ">
                            <v-list-item-action>
                              <v-icon>mdi-screwdriver</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Komponenten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-divider></v-divider>

                          <v-list-item @click.stop="
                            (PDFViewerDialog = true),
                            (PDFViewerFilename = `Artikelstammdaten`),
                            (PDFViewerURL = `https://apiv2.nexchance.de/pdf/manual/${article.ART_ID}/view`)
                          ">
                            <v-list-item-action>
                              <v-icon color="red">mdi-file-document</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Artikelstammdaten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-for="articleAttachment in orderArticlesAttachments[
                            article.ART_ID
                          ]" :key="articleAttachment.ID" @click.stop="
  (PDFViewerDialog = true),
  (PDFViewerFilename = `${articleAttachment.LINK_TEXT}`),
  (PDFViewerURL = `https://apiv2.nexchance.de/vario/articles/attachments/${articleAttachment.ID}/view`)
">
                            <v-list-item-action>
                              <v-icon color="red">mdi-file-document</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                articleAttachment.LINK_TEXT
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import PostService from "../PostService";
import ArticleComponents from "../components/Order/Article/Components";
import ArticleSpecifications from "../components/Article/Specifications";
import StatsCard from "../components/StatsCard";
import PDFViewer from "../components/PDFViewer";

export default {
  components: {
    ArticleComponents,
    ArticleSpecifications,
    StatsCard,
    PDFViewer,
  },
  data() {
    this.$store.commit("title", "Belegdetails");

    this.config = config;

    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    this.isWarranty = false;
    this.isReturn = false;

    return {
      order: new Object(),
      orderReceipts: new Object(),
      orderArticles: new Object(),
      orderAddresses: new Object(),
      orderLabels: new Object(),
      orderInvoice: new Object(),
      OrderReturnList: new Object,
      OrderWarrantyList: new Object,
      orderArticlesImages: [],
      orderArticlesAttachments: [],

      articleComponentsDialog: false,
      articleComponentsArticlePOID: false,

      articleSpecificationsDialog: false,
      articleSpecificationsArticleID: false,

      PDFViewerDialog: false,
      PDFViewerFilename: false,
      PDFViewerURL: false,
    };
  },
  methods: {
    fetchOrder(order_id) {
      this.$store.commit("loading", true);

      return this.postService
        .fetchOrder(order_id)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.order = res.data.data;

            this.$store.commit("title", `Bestellung #${this.order.BELEGNR}`);

            document.title = `${this.$store.getters.title} | neXchance IT GmbH`;

            this.fetchOrderReceipts();
            this.fetchOrderArticles();
            this.fetchOrderAddresses();
            this.fetchOrderInvoice();
            this.fetchOrdersReturn();
            this.fetchOrdersWarranty();

            this.$store.commit("loading", false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderReceipts() {
      return this.postService
        .fetchOrders({
          "filter[AUFTRAGSNR]==": this.order.BELEGNR,
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderReceipts = res.data.data;

            this.fetchOrderLabels();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderArticles() {
      return this.postService
        .fetchOrderArticles({
          "filter[BEK_ID]==": this.order.ID,
          "filter[PARENT_ID]=": null,
          "order=": "PO",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderArticles = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.fetchArticleImages();
          this.fetchArticleAttachments();
        });
    },
    fetchOrderAddresses() {
      return this.postService
        .fetchOrderAddresses({
          "filter[BEK_ID]==": this.order.ID,
          "order=": "ADR_TYP",
          "direction=": "ASC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderAddresses = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrderLabels() {
      return this.orderReceipts.forEach((order) => {
        this.postService
          .fetchOrderLabels({
            "filter[BEK_ID]==": order.ID,
            "order=": "ID",
            "direction=": "ASC",
          })
          .then((res) => {
            if (res.data && res.data.result && res.data.status === "success") {
              this.orderLabels = res.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    fetchOrderInvoice() {
      return this.postService
        .fetchOrderInvoice(this.order.ID)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.orderInvoice = res.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchArticleImages() {
      return this.orderArticles.forEach((article) => {
        this.postService
          .fetchImages({
            "filter[TABELLE]==": "ART",
            "filter[TABELLE_ID]==": article.ART_ID,
            "order=": "BILDINDEX",
            "direction=": "ASC",
          })
          .then((res) => {
            if (res.data && res.data.status === "success") {
              this.orderArticlesImages[article.ART_ID] = res.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.$forceUpdate();
          });
      });
    },
    fetchWarrantyReceiptOrders(id) {
      return this.postService
        .fetchWarrantyReceiptOrders({
          "filter[ID]=": id,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "order=": "BELEGDATUM",
          "direction=": "ASC",
          "limit=": "1",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (res.data.result === 1) {
              this.isWarranty = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchReturnReceiptOrders(id) {
      this.orderIsLoading = true;

      return this.postService
        .fetchReturnReceiptOrders({
          "filter[ID]=": id,
          "filter[BELEGTYP]==": "02",
          "filter[BELEGART]=!": "0220",
          "order=": "BELEGDATUM",
          "direction=": "ASC",
          "limit=": "20",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (res.data.result === 1) {
              this.isReturn = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.orderIsLoading = false;
        });
    },
    fetchArticleAttachments() {
      this.orderArticles.forEach((article) => {
        return this.postService
          .fetchArticleAttachments({
            "filter[TABELLE]==": "ART",
            "filter[SCHLUESSEL]==": article.ART_ID,
            "order=": "ID",
            "direction=": "ASC",
          })
          .then((res) => {
            if (res.data && res.data.status === "success") {
              this.orderArticlesAttachments[article.ART_ID] = res.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    fetchOrdersReturn() {
      this.postService
        .fetchOrdersReturn({
          "filter[order_number]==": this.order.AUFTRAGSNR,
          "order=": "id",
          "direction=": "DESC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.OrderReturnList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchOrdersWarranty() {
      this.postService
        .fetchOrdersWarranty({
          "filter[order_number]==": this.order.AUFTRAGSNR,
          "order=": "id",
          "direction=": "DESC",
        })
        .then((res) => {
          if (res.data && res.data.status === "success") {
            this.OrderWarrantyList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.fetchOrder(this.$route.params.id);
    this.fetchWarrantyReceiptOrders(this.$route.params.id);
    this.fetchReturnReceiptOrders(this.$route.params.id);
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.fetchOrder(this.$route.params.id);
        this.fetchWarrantyReceiptOrders(this.$route.params.id);
        this.fetchReturnReceiptOrders(this.$route.params.id);
      }
    },
  },
};
</script>
