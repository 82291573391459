import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/Home';
import Login from '../views/Login';
import Logout from '../views/Logout';
import OrderList from '../views/OrderList';
import OrderDetail from '../views/OrderDetail';
import OrderReturnList from '../views/OrderReturnList';
import OrderReturnDetail from '../views/OrderReturnDetail';
import OrderReturnAdd from '../views/OrderReturnAdd';
import OrderWarrantyList from '../views/OrderWarrantyList';
import OrderWarrantyDetail from '../views/OrderWarrantyDetail';
import OrderWarrantyAdd from '../views/OrderWarrantyAdd';
import RefurbedBuyBox from '../views/RefurbedBuyBox';
import RefurbedBuyBoxReset from '../views/RefurbedBuyBoxReset';
import RefurbedOfferImport from '../views/RefurbedOfferImport';
import RefurbedOfferImportNew from '../views/RefurbedOfferImportNew';
import AlertReplication from '../views/AlertReplication';
import AlertReplicationAddress from '../views/AlertReplicationAddress';
import AlertReplicationArticle from '../views/AlertReplicationArticle';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                auth: true,
                title: 'Dashboard'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                auth: false,
                title: 'Login'
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: {
                auth: false,
                title: 'Logout'
            }
        },
        {
            path: '/orders',
            name: 'orders',
            component: OrderList,
            meta: {
                auth: true,
                title: 'Alle Bestellungen'
            }
        },
        {
            path: '/return',
            name: 'orders_return',
            component: OrderReturnList,
            meta: {
                auth: true,
                title: 'Retouren'
            }
        },
        {
            path: '/return/add',
            name: 'orders_return_add',
            component: OrderReturnAdd,
            meta: {
                auth: true,
                title: 'Retoure erstellen'
            }
        },
        {
            path: '/return/add/:id',
            name: 'orders_return_add_view',
            component: OrderReturnAdd,
            meta: {
                auth: true,
                title: 'Retoure erstellen'
            }
        },
        {
            path: '/return/:id',
            name: 'orders_return_detail',
            component: OrderReturnDetail,
            meta: {
                auth: true,
                title: 'Lade...'
            }
        },
        {
            path: '/warranty',
            name: 'orders_warranty',
            component: OrderWarrantyList,
            meta: {
                auth: true,
                title: 'Serviceanträge'
            }
        },
        {
            path: '/warranty/add',
            name: 'orders_warranty_add',
            component: OrderWarrantyAdd,
            meta: {
                auth: true,
                title: 'Gratantieantrag erstellen'
            }
        },
        {
            path: '/warranty/add/:id',
            name: 'orders_warranty_add_view',
            component: OrderWarrantyAdd,
            meta: {
                auth: true,
                title: 'Gratantieantrag erstellen'
            }
        },
        {
            path: '/warranty/:id',
            name: 'orders_warranty_detail',
            component: OrderWarrantyDetail,
            meta: {
                auth: true,
                title: 'Lade...'
            }
        },
        {
            path: '/orders/:id',
            name: 'orders_detail',
            component: OrderDetail,
            meta: {
                auth: true,
                title: 'Lade...'
            }
        },
        {
            path: '/refurbed/buy-box',
            name: 'refurbed_buybox',
            component: RefurbedBuyBox,
            meta: {
                auth: true,
                title: 'BuyBox'
            }
        },
        {
            path: '/refurbed/buy-box-reset',
            name: 'refurbed_buybox_reset',
            component: RefurbedBuyBoxReset,
            meta: {
                auth: true,
                title: 'BuyBox zurücksetzen'
            }
        },
        {
            path: '/refurbed/offer/import',
            name: 'refurbed_offer_import',
            component: RefurbedOfferImport,
            meta: {
                auth: true,
                title: 'Angebote importieren'
            }
        },
        {
            path: '/refurbed/offer/import-new',
            name: 'refurbed_offer_import_new',
            component: RefurbedOfferImportNew,
            meta: {
                auth: true,
                title: 'Angebote importieren (NEU)'
            }
        },
        {
            path: '/alerts/replication/addresses',
            name: 'alerts_replication_addresses',
            component: AlertReplicationAddress,
            meta: {
                auth: true,
                title: 'Alerts: Replikation: Adressen'
            }
        },
        {
            path: '/alerts/replication/articles',
            name: 'alerts_replication_articles',
            component: AlertReplicationArticle,
            meta: {
                auth: true,
                title: 'Alerts: Replikation: Artikel'
            }
        },
        {
            path: '/alerts/replication/orders',
            name: 'alerts_replication_orders',
            component: AlertReplication,
            meta: {
                auth: true,
                title: 'Alerts: Replikation'
            }
        },
    ]
});