<template>
  <v-dialog @input="close" :value="dialog" max-width="800">
    <v-card outlined :loading="loading">
      <v-card-title class="headline">Komponenten</v-card-title>
      <v-divider></v-divider>

      <v-list two-line>
        <v-list-item link v-for="article in articles" :key="article.ID">
          <v-list-item-avatar class="secondary white--text">{{article.MENGE | formatInt}}</v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{article.BEZEICHNUNG}}</v-list-item-title>
            <v-list-item-subtitle>Artikelnummer: {{article.ARTIKELNR}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click.stop="close">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PostService from "../../../PostService";

export default {
  name: "ArticleComponents",
  data() {
    this.postService = new PostService(
      this.$store.getters.user.address.EMAIL,
      this.$store.getters.user.address.PLZ
    );

    return {
      loading: false,
      articles: false
    };
  },
  props: {
    dialog: {
      type: Boolean
    },
    orderID: {
      default: false
    },
    articleID: {
      default: false
    }
  },
  methods: {
    fetchOrderArticles() {
      this.loading = true;

      this.postService
        .fetchOrderArticles({
          "filter[BEK_ID]==": this.orderID,
          "filter[PARENT_ID]==": this.articleID,
          "filter[POART]==": "D",
          "order=": "PO",
          "direction=": "ASC"
        })
        .then(res => {
          if (res.data) {
            this.articles = res.data.data;

            this.$forceUpdate();
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:dialog", false);
    }
  },
  watch: {
    articleID: async function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchOrderArticles();
      }
    }
  }
};
</script>